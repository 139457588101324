<template>
  <!-- Small Modal -->
  <div id="small-modal" tabindex="-1"
       :class=" [showQrcode?'overflow-y-auto overflow-x-hidden flex justify-center items-center bg-[#000000b0] fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full':'hidden']"
       style="height: var(--doc-height)">
    <div class="relative p-4 ">
      <div class="relative bg-white rounded-lg shadow ">
        <div class="flex justify-between items-center  dark:border-gray-600">
          <button type="button" class="pl-8 text-blue-600 cursor-pointer underline" @click="copyLink()">
            {{ copyButtonText }}
          </button>
          <button type="button"
                  class="text-gray-400 bg-transparent
                  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
            <Icon @click.stop="showQrcode=!showQrcode" color="text-black"
                  class="cursor-pointer hover:text-red-900"
                  icon-name="XIcon"
                  :iconSize="6" icon-type="outline"/>
          </button>
        </div>
        <div class="p-2 space-y-6">
          <qrcode-vue :value="streamingLink" :size="200" level="H" v-if="streamingLink"/>
        </div>
      </div>
    </div>
  </div><!-- Small Modal -->


  <div v-if="song" :class=" ['py-4 w-full border-b px-2 ']" style="height: var(--toolbar-header-block-height)">
    <div :class="['flex justify-between items-center' ,song??'invisible bg-gray-300']">
      <button @click="closeDisplayPanel" class="p-2 border lg:invisible
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
        <Icon class="cursor-pointer" icon-name="ArrowLeftIcon" :icon-size="5" icon-type="outline"/>
      </button>
      <div class="flex justify-center items-center space-x-2">
        <span class="flex h-3 w-3" v-if="isProjecting">
          <span class="absolute rounded-full inline-flex bg-red-600 h-3 w-3 animate-ping opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
        <button @click="launchStream(song)" class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon v-if="isProjecting" color="text-red-700" class="cursor-pointer"
                icon-name="StopIcon" :icon-size="5" icon-type="solid"/>
          <Icon v-else color="text-red-700" class="cursor-pointer"
                icon-name="PresentationChartLineIcon" :icon-size="5" icon-type="outline"/>
        </button>
        <button v-if="isProjecting" @click="showQrcode=!showQrcode" class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon color="text-red-700" class="cursor-pointer"
                icon-name="QrcodeIcon" :icon-size="5" icon-type="solid"/>
        </button>
        <button v-if="song && !song.hasOwnProperty('favorite')" class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none" @click="likeOrDislike">
          <Icon color="red-600" class="cursor-pointer " icon-name="HeartIcon" :iconSize="5" icon-type="solid"/>
        </button>
        <button v-else class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon color="red-600" class="cursor-pointer " icon-name="HeartIcon" :iconSize="5" icon-type="outline"/>
        </button>
        <button id="dropdownDefault" data-dropdown-toggle="dropdown" type="button" class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon class="cursor-pointer" icon-name="GlobeAltIcon" :icon-size="5" icon-type="outline"/>
        </button>
        <!-- Dropdown menu -->
        <div id="dropdown"
             class="absolute lg:top-[55px] lg:right-[55px] sm:top-[63px] sm:right-[55px] z-10 w-44 bg-white rounded divide-y divide-black shadow">
          <ul class="hidden py-1 text-sm text-gray-700 divide-black " aria-labelledby="dropdownDefault">
            <li class="divide-black" v-for="i in 3" :key="i">
              <a href="#" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Menu
                {{ i }}</a>
            </li>
          </ul>
        </div>
        <button class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon class="cursor-pointer" icon-name="DotsVerticalIcon" :icon-size="5" icon-type="outline"/>
        </button>
      </div>
    </div><!---->
  </div>
  <div class="flex py-2 bg-gray-200 space-x-4 items-center">
    <span class="lg:text-lg font-bold  sm:text-[12px] break-words w-full text-center" v-if="song">
    {{ song.number }}. {{ song.name }} </span>
  </div>
  <div class="flex-col flex overflow-y-auto "
       :style="[song?'height: var(--main-content-admin-height)':'height: calc(var(--main-content-admin-height) + var(--toolbar-header-block-height))']">
    <ul class="flex flex-col items-center sm:py-5" v-if="song">
      <li v-for="(verse, index) in song.verses" :class="['px-7 py-2 lg:mb-5 sm:mb-1 text-center lg:w-96 hover:bg-gray-100 rounded-lg cursor-pointer ' +
       'lg:text-xl sm:text-[15px] sm:w-3/4', verse.isRefrain ? 'italic font-bold' : '',
       (verseIndex === index && isProjecting) ? 'border-2 border-blue-400' : 'bg-white' ]"
          :key="verse.id" @click="onVerseClicked(index)">
        {{ verse.line.replaceAll("\\n", "") }}
      </li>
    </ul>

    <WelcomePanel v-if="song == null"/>
  </div>

  <player-bar :song="song"/>
</template>

<script>

import templateSetting from "@/mixins/templateSetting";
import {
  GET_FAVORITES,
  GET_SONG_BY_ID,
  LIKE_OR_DISLIKE,
  SET_OPEN_PANEL,
  SET_PROJECTOR_STATE,
  SET_STREAMING_LINK
} from "../../../store/types";
import PlayerBar from "../../../components/PlayerBar";
import WelcomePanel from "../../../components/WelcomePanel";
import QrcodeVue from "qrcode.vue";
import websocket from "../../../mixins/websocket";

export default {
  name: "FavoritePage",

  mixins: [templateSetting, websocket],
  components: {WelcomePanel, PlayerBar, QrcodeVue},
  data() {
    return {
      showQrcode: false,
      copyButtonText: 'Copy the link',
    }
  },

  beforeCreate() {
    this.$store.dispatch(GET_FAVORITES)
    if (!this.$route.query.song) {
      this.$store.commit(SET_OPEN_PANEL, false)
    }
    this.$store.dispatch(GET_SONG_BY_ID, this.$route.query.song)
  },

  created() {
    window.addEventListener("keydown", this.keyboardEventHandler);
  },

  destroyed() {
    window.removeEventListener("keydown", this.keyboardEventHandler);
  },
  watch: {
    showQrcode(newVale) {
      if (!newVale) {
        this.copyButtonText = 'Copy the link'
      }
    },
    isProjecting(newValue) {
      this.$store.commit(SET_PROJECTOR_STATE, newValue)
    }
  },
  computed: {
    currentSongbook() {
      return this.$store.state.favorite;
    },
    song() {
      return this.$store.state.song
    },
    verseIndex() {
      return this.$store.state.stream.verseIndex;
    },
    room() {
      return this.$store.state.roomNumber;
    },
    streamingLink() {
      return this.$store.state.streamingLink;
    },
    songLength() {
      return this.$store.state.song.verses.length - 1;
    },
    isProjecting() {
      return this.stompClient && this.stompClient.connected;
    }
  },
  methods: {
    onVerseClicked(index) {
      this.sendData(index);
    },

    sendData(newIndex) {
      this.send(`/stream/${this.room}`, {
        songId: this.song.id,
        verseIndex: newIndex
      })
    },

    keyboardEventHandler(e) {
      if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
        if (this.prevVerse())
          this.sendData(this.verseIndex);
      } else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
        if (this.nextVerse())
          this.sendData(this.verseIndex);
      }
    },

    nextVerse() {
      if (this.verseIndex < this.songLength) {
        this.$store.commit(SET_VERSE_INDEX, this.verseIndex + 1)
        return true;
      }
      return false;
    },

    prevVerse() {
      if (this.verseIndex >= 1) {
        this.$store.commit(SET_VERSE_INDEX, this.verseIndex - 1)
        return true;
      }
      return false;
    },

    generateRoomNumber() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

    launchStream() {
      if (this.isProjecting) {
        this.disConnect()
      } else {
        if (!this.room) {
          this.$store.commit(SET_ROOM_NUMBER, this.generateRoomNumber())
        }
        this.connect(this.room)
        let routeData = window.location.origin + this.$router.resolve({
          name: 'StreamPage',
          params: {room: this.room}
        }).path
        this.$store.commit(SET_STREAMING_LINK, routeData)
        this.send(`/stream/${this.room}`, {
          songId: this.song.id,
          verseIndex: 0
        })
      }
    },

    copyLink() {
      navigator.clipboard.writeText(this.streamingLink);
      this.copyButtonText = 'copied'
    },
    likeOrDislike() {
      this.$store.dispatch(LIKE_OR_DISLIKE, this.song)
    },
  }
}
;
</script>
<style scoped>
</style>
