import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './registerServiceWorker'
import './assets/style/tailwind.css'
import Toast from "vue-toastification";
import vueInsomnia from "vue-insomnia";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import auth from './funcs/func'
import HeroIcon from "./components/common/HeroIcon";

const toastOptions = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    position: "top-right",
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false,
    shareAppContext: true,
};

const app = createApp(App) //createSSRApp(App)
app.component('Icon', HeroIcon)
app.use(store).use(router).use(auth).use(vueInsomnia).use(Toast, toastOptions).mount("#app");
