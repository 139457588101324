import * as type from "../types";
import {CHANGE_SESSION} from "../types";

export default {

    [type.GET_USER_ME]: ({commit, getters}) =>
        getters.GET(`/users/me`).then((data) => commit(type.GET_USER_ME, data)
        ),

    [type.GET_USERS]: ({commit, getters}) =>
        getters.GET(`/users/users`).then((data) => commit(type.GET_USERS, data)
        ),

    [type.POST_REGISTER_USER]: ({commit, getters}, payload) => {
        return getters.POST(`/users/register`,  payload).then((data) => {
            commit(type.POST_REGISTER_USER, data);
            return data
        });
    },

    [type.SEND_AUTH]: ({commit, getters}, payload) => {
        return getters.POST(`/users/login`,  payload).then((body) => {
            if (body.code===200){
                commit(CHANGE_SESSION, body.data)
            }
            // commit(type.SEND_AUTH, data);
            return body
        });
    },

    [type.POST_GRANT_USER]: ({commit, getters}, payload) => {
        return getters.POST(`/users/grant`,  payload).then(() => {});
    },

    [type.POST_UNGRANT_USER]: ({commit, getters}, payload) => {
        return getters.POST(`/users/ungrant`,  payload).then((user) => {
            commit(type.POST_UNGRANT_USER, user);
            return user
        });
    },

};
