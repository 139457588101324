<template>
  <div v-if="currentStream && currentStream.song" class="select-none" ref="streamScreen">
    <div
        class="sticky h-[60px] bg-gray-200 w-full flex flex-row items-center md:pl-0 md:justify-center sm:justify-start sm:pl-3">
      <router-link class="absolute left-3 top-1/2 -translate-y-1/2 cursor-pointer" :to="{name: 'Home'}">
        <Icon color="text-black" class="cursor-pointer" icon-name="HomeIcon" :iconSize="6"
              icon-type="outline"/>
      </router-link>
      <div class="font-bold">
        {{ currentStream.song.number }} - {{ currentStream.song.name }}
        <span v-if="currentStream.song.mainChord">({{ currentStream.song.mainChord }})</span>
      </div>
      <div class="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer" @click="toggleProjector">
        <Icon color="text-black" class="cursor-pointer" icon-name="ArrowsExpandIcon" :iconSize="6"
              icon-type="outline"/>
      </div>
    </div>
    <!--  Song list -->
    <div class="flex-col flex overflow-y-auto " style="height: var(--main-content-admin-height)"
         v-if="currentStream.song">
      <ul class="flex flex-col items-center sm:py-5" v-if="currentStream.song">
        <li v-for="(verse, index) in currentStream.song.verses" :class="['px-7 py-2 lg:mb-5 sm:mb-5 text-center lg:w-96 rounded-lg lg:text-xl sm:text-[15px] sm:w-3/4',
              verse.isRefrain?'italic font-bold':'', (currentStream.verseIndex === index && isProjecting) ? 'border-2 border-blue-400' : 'bg-white' ]"
            :key="verse.id">{{ verse.line.replaceAll("\\n", "") }}
        </li>
      </ul>
    </div>

    <!--  Projector -->
    <div
        class="fixed top-0 left-0 flex flex-col justify-between items-center w-full bg-black select-none text-white text-center"
        v-if="projectorMode" style="height:var(--doc-height)">
      <div class="absolute top-2 right-2" @click="toggleProjector">
        <Icon color="text-white" class="cursor-pointer" icon-name="XIcon" :iconSize="6" icon-type="outline"/>
      </div>

      <div class="mt-7 font-bold sm:text-sm md:text-lg lg:text-xl">
        {{ currentStream.song.number }} - {{ currentStream.song.name }}
        <span v-if="currentStream.song.mainChord"> ({{ currentStream.song.mainChord }})</span>
      </div>

      <div class="leading-relaxed text-3xl sm:text-lg sm:w-3/4 md:text-3xl lg:text-4xl lg:w-3/5 ">
        {{ currentVerse }}
      </div>

      <ul class="flex flex-row mb-5">
        <li v-for="(verse, index) in verseOrder" :key="index"
            :class="['font-bold mx-2 pb-2 px-1 sm:text-sm md:text-lg lg:text-xl', currentStream.verseIndex === index ? 'border-b-2 border-white' : '']">
          {{ verse }}
        </li>
      </ul>
    </div>
  </div>
  <!--  <loader label-text="You're connected to the stream..." v-else/>-->
  <div v-else class="flex justify-center items-center w-full h-full">
    <!--  <div>fff</div>-->
  </div>
</template>

<script>
import websocket from "../../../mixins/websocket";
import Loader from "../../../components/Loader";
import {GET_LAST_STREAM_MESSAGE} from "../../../store/types";


export default {
  name: "StreamPage",
  components: {Loader},
  mixins: [websocket],
  data() {
    return {
      room: this.$route.params.room,
      projectorMode: true,
      wakeLock: null,
    }
  },
  beforeCreate() {
    this.$store.dispatch(GET_LAST_STREAM_MESSAGE, this.$route.params.room)

  },
  async created() {
    this.connect(this.room)
  },

  beforeUnmount() {
    this.vueInsomnia().off();
  },

  mounted() {
    this.vueInsomnia().on();
// Function that attempts to request a wake lock.
//     this.requestWakeLock().then(
//         ()=>{
//           this.releaseWakeLock()
//         }
//     )
  },

  computed: {
    currentStream() {
      return this.$store.state.stream;
    },
    isProjecting() {
      return this.stompClient && this.stompClient.connected;
    },
    verseOrder() {
      let verses = this.currentStream.song.verses;
      return verses.map((verse, index) => {
        return verse.isRefrain ? 'Ref.' : `V${index + 1}`
      });
    },
    currentVerse() {
      return this.currentStream.song.verses[this.currentStream.verseIndex].line.replaceAll("\\n", "");
    }
  },
  methods: {
    toggleProjector() {
      this.projectorMode = !this.projectorMode;
    },

    async requestWakeLock() {
      try {
        this.wakeLock = await navigator.wakeLock.request('screen');
        this.wakeLock.addEventListener('release', () => {
          console.log('Wake Lock was released');
        });
        console.log('Wake Lock is active');
        return this.wakeLock
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    },
    async releaseWakeLock() {
      if (!this.wakeLock) {
        return;
      }
      try {
        await this.wakeLock.release();
        this.wakeLock = null
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    }
  },
}
</script>

<style scoped>
div {
  font-family: 'Inter', sans-serif;
}
</style>