import * as type from "../types";
import {CHANGE_VIEW_MODE, ON_ICON_SEARCH_CLICKED} from "../types";

export default {

    [type.SET_LAYOUT]: (state, layout) => {
        state.layout = layout;
    },

    [type.GET_CURRENT_LANGUAGE]: (state, language) => {
        state.currentLanguage = language;
    },

    [type.GET_CURRENT_SONGBOOK]: (state, songbook) => {
        state.currentSongbook = songbook;
    },

    [type.LOADER_ACTION]: (state, isLoading) => {
        state.waitLoader = isLoading;
    },

    [type.SET_OPEN_MENU]: (state, isOpen) => {
        state.displayMenu = isOpen;
    },

    [type.ON_ICON_SEARCH_CLICKED]: (state, isOpen) => {
        state.searchOpen = isOpen;
    },

    [type.SET_OPEN_PANEL]: (state, isOpen) => {
        localStorage.setItem("isOpen", isOpen)
        state.displayPanel = isOpen;
    },

    [type.CHANGE_VIEW_MODE]: (state, viewMode) => {
        state.viewMode = viewMode;
    },
};
