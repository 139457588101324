import * as type from "../types";

export default {
    [type.GET_LAST_STREAM_MESSAGE]: ({commit, getters}, room) =>
        getters.GET_WITH_LOADER(`/sockets/${room}`, commit)
            .then((data) => {
                if (data)
                    commit(type.GET_LAST_STREAM_MESSAGE, data);
                return data;
            }),


};
