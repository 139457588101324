<template>
  <div>
    <div class="py-4 w-full border-b px-2 flex justify-between  items-center"
         style="height: var(--toolbar-header-block-height)">
      <button @click="closeDisplayPanel" class="p-2 border lg:invisible
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
        <Icon class="cursor-pointer" icon-name="ArrowLeftIcon" :icon-size="5" icon-type="outline"/>
      </button>
      <span v-if="song"> {{ song.name }}</span>
    </div>
    <div class="border-b-2 border-blue-600 " style="height: var(--tab-content-block)">
      <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent"
          role="tablist">
        <li class="mr-2" role="presentation">
          <button @click.prevent="showBlock($event)"
                  :class="[currentId == 'songLyrics' ? '':'active-tab','inline-block p-4 rounded-t-lg border-b-2  hover:text-blue-600'] "
                  id="songLyrics" data-tabs-target="#songlyrics" type="button" role="tab" aria-controls="songlyrics"
                  aria-selected="true">Lyrics
          </button>
        </li>
        <li class="mr-2" role="presentation">
          <button @click.prevent="showBlock($event)"
                  :class="[currentId == 'songmedia' ? '':'active-tab','inline-block p-4 rounded-t-lg border-b-2   hover:text-gray-600 hover:border-blue-600 text-gray-500  ']"
                  id="songmedia" data-tabs-target="#songmedia" type="button" role="tab" aria-controls="songmedia"
                  aria-selected="false">Media
          </button>
        </li>
      </ul>
    </div>
    <div id="myTabContent" class="overflow-y-auto"
         style="height: calc(var(--main-content-admin-height) - var(--tab-content-block))">
      <div aria-labelledby="songLyrics"
           :class="[currentId !== 'songLyrics' ? 'hidden':'flex' ,' flex-col items-center']" v-if="song">
        <div v-for="(verse, index) in song.verses" :key="verse.id" class="pb-4 w-2/3 pt-2">
          <textarea rows="5" :value="verse.line" class="w-full"/>
        </div>
      </div>
      <div :class="[currentId !== 'songmedia' ?'hidden':'block', 'w-full h-full']" id="songmedia" role="tabpanel"
           aria-labelledby="songmedia">
        <div class="flex justify-center  h-full">
          Edit Chord A Song Author and audio uploader section
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import templateSetting from "@/mixins/templateSetting";

export default {
  name: "DashboardEditSongPage",
  mixins: [templateSetting],
  computed: {
    song() {
      return this.$store.state.song
    },
  },

  data() {
    return {
      currentId: 'songLyrics'
    }
  },

  methods: {
    showBlock(e) {
      this.currentId = e.target.id
    }
  }
};
</script>