import * as type from "../types";

export default {
    [type.GET_USER_ME]: (state, user) => {
        state.user = user
    },

    [type.GET_USERS]: (state, users) => {
        state.users = users
    },

    [type.POST_UNGRANT_USER]: (state, user) => {
        state.user = user
    },

    [type.POST_GRANT_USER]: (state, user) => {
        state.user = user
    },

    [type.POST_GRANT_USER]: (state, user) => {
        state.user = user
    },

    [type.POST_REGISTER_USER]: (state, user) => {
        state.user = user
    },
    [type.CHANGE_SESSION]: (state, sessions) => {
        window.localStorage.setItem('TOKEN', sessions.access_token);
        state.sessions = sessions
    },
    [type.LOGOUT]: (state) => {
        window.localStorage.removeItem('TOKEN');
        state.sessions = {
            access_token: null,
            user: null
        }
    },


};
