import language from "./mutations/language";
import songbooks from "./mutations/songbooks";
import songs from "./mutations/songs";
import template from "@/store/mutations/template";
import toast from "./mutations/toast";
import auth from "./mutations/auth";
import radio from "./mutations/radio";
import stream from "./mutations/stream";
import favorite from "./mutations/favorite";
import player from "./mutations/player";

const mutations = Object.assign({}, language, songbooks, songs, template, toast, auth, stream, radio, favorite, player);

export default mutations;
