import {SET_ROOM_NUMBER} from "./types";

const state = {
    displayMenu: false,
    waitLoader: false,
    displayPanel: localStorage.getItem("isOpen") || false,
    showSelect: false,
    toast: null,
    isOpenEditPanel: false,

    searchOpen: false,

    layout: 'main-layout',
    viewMode: false,

    sessions: {
        access_token: window.localStorage.getItem('TOKEN'),
        user: {}
    },

    languages: [],
    translatedLanguages: [],
    language: null,

    songbooks: [],
    songbook: null,
    songbookReport: null,

    songs: [],
    song: null,
    songCodes: [],
    mainChords:[],
    favorite:null,
    favorites:[],
    currentLanguage: null,
    currentTempLanguage: null,
    currentSongbook: {},

    user: {},
    users: {},

    stream:{
        verseIndex: 0,
        song: null,
    },
    roomNumber: localStorage.getItem(SET_ROOM_NUMBER),
    isProjecting: false,
    streamingLink: null,
    station: {
        id: 1,
        number: "001",
        audio: true,
        chord: false,
        code: null,
        chordUrl: null,
        name: null,
        description: null,
        audioUrl: null,
        songBook: {
            id: 400,
            name: "Radio station",
            code: "radio_streaming",
            userId: 3,
            addedBy: "SYSTEM",
            description: null,
            languageId: 13,
            isDraft: false,
            version: "v0.0.1",
            language: {
                id: 13,
                name: "Default",
                code: "de"
            }
        },
        author: null,
        language: {
            id: 13,
            name: "Tshiluba",
            code: "lua"
        }
    },
    stations: [],
    currentStationId: null,


    isPlaying: false,
    current: {
        url: null,
        isStream: true
    }
};

export default state;
