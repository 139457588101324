import * as type from "../types";

export default {
    [type.GET_FAVORITES]: ({commit, getters}) =>
        getters.ADMIN_GET(`/favorites`, commit)
            .then((data) => {
                if (data)
                    commit(type.GET_FAVORITES, data);
                return data;
            }),

    [type.LIKE_OR_DISLIKE]: ({commit, getters}, song) =>
        getters.ADMIN_POST(`/favorites/likeOrDislike`, song).then((data) => {
                // if (data)
                    // commit(type.GET_FAVORITES, data)
            }
        ),

};
