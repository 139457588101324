<template>
  <div>
    <div class="flex" style="height: var(--doc-height)">
      <div class="lg:w-1/3 w-full  relative h-full flex-col"
           :class="[showSongDetail ? 'hidden lg:flex lg:w-1/3' : currentRouteName === 'DashboardHomePage' ? 'lg:w-[100px] hidden lg:flex ': 'flex lg:w-1/3 w-full', ]">
        <router-view name="asideBlock"/>
        <router-view name="userBar"/>
      </div>
      <div class=" relative lg:w-2/3 w-full flex-col border-l"
           :class="[currentRouteName === 'DashboardHomePage' ? 'lg:w-full':showSongDetail ? 'flex':'hidden lg:flex' ]">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import {LOGOUT} from "@/store/types";
import utils from "@/funcs/routehandler";

const documentHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
}
window.addEventListener(`resize`, documentHeight)
documentHeight()
export default {
  name: "MainLayout",
  created() {
    this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case LOGOUT:
          this.$router.push({name: 'Login'});
          break;
      }
    })
  },
  mounted() {

  },

  beforeCreate() {
  },

  computed: {
    waitLoader() {
      return this.$store.state.waitLoader
    },
    showSongDetail() {
      return this.$store.state.displayPanel
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>