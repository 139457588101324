import * as type from "../types";
import _ from 'lodash'

export default {
    [type.TOAST]: (state, text) =>{
        state.toast = text
    },

    [type.INITIALIZE_OPEN_EDIT_PANEL]: (state) => {
        if (localStorage.getItem('atmos_Panel')) {
            const isOpen = JSON.parse(localStorage.getItem('atmos_Panel'))
            if(!_.isEmpty(isOpen)){
                state.isOpenEditPanel = isOpen.open
            }
        } else {
            localStorage.setItem('atmos_Panel', JSON.stringify({open : false}))
        }
    },

    [type.SET_OPEN_EDIT_PANEL]: (state, isOpen) => {
        state.isOpenEditPanel = isOpen
        localStorage.setItem('atmos_Panel', JSON.stringify({open : isOpen}))
    },

}