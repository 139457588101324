<template>
  <div class="w-full px-2">
    <div class="flex justify-between ">
      <div>
        <TransitionRoot as="template" :show="open" appear>
          <Dialog as="div" class="fixed inset-0 overflow-hidden" @close="onClickEvent">
            <div class="absolute inset-0 overflow-hidden">
              <!-- Dark overlay -->
              <TransitionChild
                  as="template"
                  enter="ease-in-out duration-500"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-500"
                  leave-from="opacity-100"
                  leave-to="opacity-0">
                <DialogOverlay
                    class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
              </TransitionChild>

              <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
                <TransitionChild
                    as="template"
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full">
                  <div class="pointer-events-auto relative w-screen max-w-md">
                    <TransitionChild
                        as="template"
                        enter="ease-in-out duration-500"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in-out duration-500"
                        leave-from="opacity-100"
                        leave-to="opacity-0">
                      <div class="absolute top-0 right-0 ml-8 flex pt-4 pl-2 sm:pl-0 sm:mr-5">
                        <button type="button" class="rounded-md text-gray-300 outline-none sm:text-gray-700"
                                @click="onClickEvent">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true"/>
                        </button>
                      </div>
                    </TransitionChild>

                    <!-- Menu content -->
                    <div class="flex h-full flex-col overflow-hidden bg-white py-6 shadow-xl">
                      <!-- Title -->
                      <div class="px-4 sm:px-6">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          Atmosphere <br/>
                          <span class="text-base font-medium text-gray-300">v{{ appVersion }}</span>
                        </DialogTitle>
                      </div>
                      <!-- Menu content -->
                      <div class="relative mt-6 flex-1 px-4 sm:px-6">
                        <CustomSelect :items="languages"
                                      label="Choose a language"
                                      :selectedItem="selectedLanguage!==null?selectedLanguage:currentLanguage"
                                      @onItemClick="onClickLanguage"/>
                        <div class="w-full block my-7"></div>
                        <CustomSelect :items="songbooks"
                                      label="Choose a songbook"
                                      :selectedItem="selectedSongBook!==null?selectedSongBook:currentSongbook"
                                      @onItemClick="onClickSongBook"/>
                        <div class="w-full my-8"></div>
                      </div>
                    </div>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </div>
    </div>

  </div>
</template>

<script>
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,} from "@headlessui/vue";
import {XIcon} from "@heroicons/vue/outline";
import {
  GET_CURRENT_LANGUAGE,
  GET_CURRENT_SONGBOOK,
  GET_LANGUAGES,
  GET_SONG_ANALOG,
  GET_SONGBOOKS_BY_LANGUAGE,
  GET_SONGS,
  GET_SONGS_BY_SONGBOOK,
  LANGUAGE,
  SET_CURRENT_TEMP_LANGUAGE,
  SET_OPEN_MENU,
  SONG,
  SONGBOOK
} from "@/store/types";
import utils from "@/funcs/routehandler";
import CustomSelect from "./common/CustomSelect";

import templateSetting from "@/mixins/templateSetting";
import {ON_ICON_SEARCH_CLICKED} from "../store/types";
export default {
  name: "MainMenu",
  mixins: [templateSetting],
  components: {
    CustomSelect,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },

  beforeCreate() {
    this.$store.dispatch(GET_LANGUAGES);
  },

  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      selectedLanguage: null,
      selectedSongBook: null,
      openMenu: false,

    };
  },

  watch: {
    search(searchedWord) {
      if (searchedWord.length > 0) {
        this.searchSong(searchedWord);
      } else {
        this.initSongsState();
      }
    },

    searchOpen(newValue) {
      if (!newValue) {
        if (this.songsForFilter.length > 0) {
          this.initSongsState();
        }
      }
    },
    open(newValue) {
      let currentSongBook = this.$store.state.currentSongbook
      let currentLanguage = this.$store.state.languages
      if (newValue === false && currentSongBook && currentSongBook.language.code !== currentLanguage.code) {
        this.selectedLanguage = currentSongBook.language
        this.$store.dispatch(GET_SONGBOOKS_BY_LANGUAGE, this.selectedLanguage.id);
      }
    }
  },
  mounted() {
    // if(this.songbooks.length>0)
    //   this.songbooks[0].language.code===this.currentTempLanguage.code
  },
  computed: {
    currentSongBook() {
      return this.$store.state.currentSongbook;
    },
    songs() {
      return this.$store.state.songs;
    },
    currentTempLanguage() {
      return this.$store.state.currentTempLanguage;
    },
    languages() {
      return this.$store.state.languages;
    },
    songbooks() {
      return this.$store.state.songbooks;
    },
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },
    currentSongbook() {
      return this.$store.state.currentSongbook;
    },
    open() {
      return this.$store.state.displayMenu;
    },
  },

  methods: {
    openSearch() {
      this.searchOpen = true;
      setTimeout(() => {
        document.getElementById("searchField").focus();
      }, 5);
    },


    onClickLanguage(item) {
      this.updateCurrentLanguageState(item)
    },

    onClickSongBook(item) {
      this.updateCurrentSongbookState(item)
    },

    updateCurrentLanguageState(language) {
      if (parseInt(language.id) !== parseInt(this.currentLanguage.id)) {
        this.$store.commit(SET_CURRENT_TEMP_LANGUAGE, language);
        this.$store.dispatch(GET_SONGBOOKS_BY_LANGUAGE, language.id);
      }
      this.selectedLanguage = language
    },

    updateCurrentSongbookState(songBook) {
      this.selectedSongBook = songBook
      if (parseInt(this.currentSongbook.id) !== parseInt(songBook.id)) {
        this.updateSongsState(songBook)
        this.$store.commit(GET_CURRENT_SONGBOOK, songBook);
        this.initCurrentLanguage(songBook);
        this.updateQueryParams(songBook)
      }
      this.$store.commit(ON_ICON_SEARCH_CLICKED, false)
      this.$store.commit(SET_OPEN_MENU, !this.$store.state.displayMenu);
    },

    updateSongsState(songBook) {
      this.$store.dispatch(GET_SONGS_BY_SONGBOOK, songBook.id);
      this.$store.commit(GET_SONG_ANALOG, null);
    },

    updateQueryParams(songBook) {
      this.initQueryParams(songBook)
      utils.initScroll();
      utils.spyScrollSongListPanel(0);
    },

    initCurrentLanguage(songbook) {
      this.$store.commit(GET_CURRENT_LANGUAGE, songbook.language);
    },
  },
};
</script>
