<template>
  <div>
    <div class="fixed w-auto bottom-0 right-4 mb-4 z-50">
      <transition name="toast">
        <div v-if="toast" :class="[toast.type == 'error' ? 'bg-red-500' : 'bg-green-500', 'flex space-x-4 items-center px-4 rounded-md'] " >
          <div class="text-white text-md  py-3 shadow-sm rounded flex items-center space-x-3">
            <icon  class="text-gray-300 cursor-pointer"  icon-type="solid" icon-name="CheckCircleIcon" :icon-size="5"/>
            <span>{{ toast.text }}</span>
          </div>
          <icon @click.prevent="onClose" class="text-gray-300 cursor-pointer" icon-type="outline" icon-name="XIcon" :icon-size="5"/>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import {TOAST} from "../../store/types";

export default {
  name: 'Alert',

  computed: {
    toast(){
      return this.$store.state.toast
    }
  },

  methods:{
    onClose(){
      this.$store.dispatch(TOAST, {time:0})
    }
  }
}
</script>