<template>
  <div class="text-left hover:bg-gray-100 border-b text-lg focus:bg-blue cursor-pointer sm:text-sm"
       v-for="song in songs" v-bind:key="song.id">
    <div class="flex justify-between lg:text-sm sm:text-[12px]">
      <div class="flex w-full p-4 " @click.prevent="$emit('onSongClicked', song)">
            <span>
              <span class=" mr-2" v-if="song?.currentMedia===undefined">{{ song.number }}</span>
              {{ song.name }} <span v-if="song.mainChord">({{song.mainChord}})</span>
            </span>
        <sup>
          <Icon v-if="song.audio" color="text-blue-600" class="cursor-pointer" icon-name="MusicNoteIcon"
                :iconSize="2"
                icon-type="outline"/>
        </sup>
      </div>
      <div class="p-4 flex justify-center items-center" v-if="song.favorite">
        <Icon color="red-600" class="cursor-pointer " icon-name="HeartIcon" :iconSize="5" icon-type="solid"/>
      </div>
    </div>
  </div>
</template>

<script>
import songListMixin from "../../mixins/songListMixin";

export default {
  name: "SongListLineView",
  mixins: [songListMixin]
}
</script>

<style scoped>

</style>