import * as type from "../types";

export default {
  [type.GET_SONGBOOKS]: (state, songbooks) => {
    state.songbooks = songbooks;
  },

  [type.GET_SONGBOOKS_BY_LANGUAGE]: (state, songbooks) => {
    state.songbooks = songbooks;
  },

  [type.GET_SONGBOOK]: (state, songbook) => {
    state.songbook = songbook;
  },

  [type.UPLOAD_SONGBOOKS]: (state, songbookReport) => {
    state.songbookReport = songbookReport;
  },
};
