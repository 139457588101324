<template>
  <qrcode-stream @init="onInit" @decode="onDecode" :track="paintBoundingBox">
    <div class="loading-indicator" v-if="loading">
      Loading...
    </div>
  </qrcode-stream>
</template>

<script>
import {QrcodeStream} from "qrcode-reader-vue3";

export default {
  name: "QrCodeReader",
  components: {QrcodeStream},
  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onInit(promise) {
      this.loading = true

      try {
        await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    onDecode(d) {
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {boundingBox: {x, y, width, height}} = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
      window.open(detectedCodes[0].rawValue,'_self') //'_self')
    },
  },
}
</script>

<style scoped>

</style>