<template>
  <div class="bg-yellow-500 h-full ">
    <div class="bg-yellow-500 flex justify-center h-full items-center overflow-y-auto "  style="max-height: calc(var(--doc-height) - 220px)" >
      Icons
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHomeAsideBlock",

  computed:{
    currentRouteName(){
      return this.$route.name
    }
  }
}
</script>

<style scoped>

</style>