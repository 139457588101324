<template>
  <div class="flex flex-wrap items-center justify-center overflow-y-auto">
    <div v-for="song in songs" v-bind:key="song.id" @click.prevent="$emit('onSongClicked', song)"
         class="m-2 cursor-pointer border border-black-200 hover:bg-gray-500
         hover:text-white text-gray-500 shadow bg-white p-2 rounded-full">
      {{ song.number }}
    </div>
  </div>
</template>

<script>
import songListMixin from "../../mixins/songListMixin";

export default {
  name: "SongListGridView",
  mixins:[songListMixin]
}
</script>

<style scoped>

</style>