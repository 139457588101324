<template>
  <div class="flex justify-between mx-1 mt-1 items-center">
    <form class="flex w-full">
      <div class="relative w-full">
        <Icon class="cursor-pointer absolute  text-slate-300 left-3  top-4" icon-name="SearchIcon" :icon-size="5"
              icon-type="outline"/>
        <input
            class="appearance-none w-full text-base leading-6 text-slate-900 placeholder-slate-400 rounded-md outline-none py-3 pl-10 border"
            id="searchField" autocomplete="off" aria-label="Search songs..." placeholder="Search songs..."
            v-on:keydown.enter.prevent
            :value="search"
            @input="search = $event.target.value">
        <Icon v-if="search.length>0" @click="()=> this.search=''"
              class="cursor-pointer absolute  text-red-300 right-3  top-4" icon-name="XIcon" :icon-size="5"
              icon-type="outline"/>
      </div>
    </form>
  </div>
</template>

<script>
import {GET_SONGS} from "@/store/types";
import Fuse from "fuse.js";
import CustomSelect from "@/components/common/CustomSelect.vue";

export default {
  name: "Search",
  components: {CustomSelect},
  props: {
    songs: {
      type: Array,
      default: [],
      required: true
    }
  },
  data() {
    return {
      selectedChord: "",
      search: "",
      songsForFilter: [],
      none: 'none',
    }
  },
  watch: {
    selectedChord(newValue) {
      console.log("this.songsForFilter ", this.songsForFilter)
      console.log("searchedWord ", newValue)
      if (newValue && newValue !== this.none) {
        this.filterSongs(newValue)
      } else {
        this.filterSongs(null)
      }
    },
    search(searchedWord) {
      if (searchedWord.length > 0) {
        if ((searchedWord.startsWith("(") || searchedWord.startsWith(".")) && this.chords && this.chords.length > 0) {
          if (searchedWord && searchedWord !== this.none) {
            this.filterSongs(searchedWord.substr(1))
          } else {
            this.filterSongs(null)
          }
        } else {
          this.searchSong(searchedWord);
        }
      } else {
        if (this.songsForFilter.length > 0)
          this.initSongsState();
      }
    },
  },
  computed: {
    chords() {
      return this.$store.state.mainChords
    }
  },
  methods: {
    searchSong(word) {
      if (this.songsForFilter.length === 0) {
        this.songsForFilter = [...this.songs];
      }

      const options = {
        // includeScore: true,
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        includeMatches: true,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        useExtendedSearch: true,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: ['number', 'name', 'verses.line']
      }
      const fuse = new Fuse(this.songs, options);
      this.$store.commit(
          GET_SONGS,
          fuse.search(word).map(t => t.item)
      );
    },

    filterSongs(chord) {
      let songs
      if (chord) {
        songs = this.sortSongs(chord)
      } else {
        songs = this.songs.sort((a, b) => a.number - b.number)
      }
      this.$store.commit(
          GET_SONGS,
          songs
      )
    },

    sortSongs(letter) {
      return this.songs.sort((a, b) => {
        const nameA = a.mainChord.toLowerCase();
        const nameB = b.mainChord.toLowerCase();

        // Check if name starts with 'letter'
        const startsWithLetterA = nameA.startsWith(letter.toLowerCase());
        const startsWithLetterB = nameB.startsWith(letter.toLowerCase());

        // Prioritize objects starting with 'letter'
        if (startsWithLetterA && !startsWithLetterB) {
          return -1;
        }
        if (!startsWithLetterA && startsWithLetterB) {
          return 1;
        }

        // Sort alphabetically for other cases
        return nameA.localeCompare(nameB);
      });
    },

    initSongsState() {
      this.$store.commit(GET_SONGS, this.songsForFilter);
      this.songsForFilter = [];
    },
  }
}
</script>