<template>
  <div class="flex flex-col justify-center bg-gray-100 items-center lg:h-screen lg:max-auto lg:p-0 p-6">
    <h4 class="text-xl font-bold">Contributors</h4>
    <div class="grid grid-cols-2 lg:grid-cols-5 gap-4 lg:container py-8 lg:overflow-y-auto lg:px-4" style="height: var(--doc-height)">
      <div v-for="(contr, index) in contributorsTab" :key="index" class="hover:border-l-2 hover:border-gray-500 shadow-lg flex items-center flex-col transform transition duration-300 ease-in hover:scale-105 cursor-pointer ">
        <div class="w-40 h-40 rounded-full border-4 border-gray-400 ">
          <img :src="contr.image" class="rounded-full w-full h-full object-cover">
        </div>
        <div class="flex flex-col items-center w-full h-full mt-4">
          <span class="font-bold">{{contr.name}}</span>
          <span class="text-gray-500">{{contr.role}}</span>
          <span class="hidden 2xl:block text-justify px-4 py-2 text-gray-500">{{contr.text}}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Contributors",

  data() {
    return {
      contributorsTab: [
        {
          "name": "Rae Morris",
          "phone": "1-408-854-3626",
          "email": "magnis@icloud.couk",
          "address": "299-5037 Et, Avenue",
          "role":"Software Engineer",
          "country": "Turkey",
          "text": "gravida sit amet, dapibus id, blandit at, nisi. Cum sociis",
          "region": "Navarra",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Mohammad Mcleod",
          "phone": "(446) 778-2701",
          "email": "tincidunt@hotmail.ca",
          "address": "605-2351 Id Rd.",
          "role":"Software Engineer",
          "country": "Poland",
          "text": "Ut tincidunt orci quis lectus. Nullam suscipit, est ac facilisis",
          "region": "Donetsk oblast",
          image:'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Keane Forbes",
          "phone": "(747) 729-7916",
          "email": "congue.a@yahoo.couk",
          "address": "Ap #473-7123 Odio. Road",
          "role":"Software Engineer",
          "country": "Singapore",
          "text": "eget metus. In nec orci. Donec nibh. Quisque nonummy ipsum",
          "region": "Illinois",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Wyatt Parrish",
          "phone": "1-846-454-7485",
          "email": "orci.phasellus@hotmail.org",
          "address": "5660 Tempor Road",
          "role":"Software Engineer",
          "country": "Italy",
          "text": "est, vitae sodales nisi magna sed dui. Fusce aliquam, enim",
          "region": "Oyo",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Dennis Sullivan",
          "phone": "(256) 446-8745",
          "email": "ac.mattis@protonmail.edu",
          "address": "Ap #286-1581 Eleifend St.",
          "role":"Software Engineer",
          "country": "France",
          "text": "pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper,",
          "region": "Ross-shire",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Wyatt Parrish",
          "phone": "1-846-454-7485",
          "email": "orci.phasellus@hotmail.org",
          "address": "5660 Tempor Road",
          "role":"Software Engineer",
          "country": "Italy",
          "text": "est, vitae sodales nisi magna sed dui. Fusce aliquam, enim",
          "region": "Oyo",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Dennis Sullivan",
          "phone": "(256) 446-8745",
          "email": "ac.mattis@protonmail.edu",
          "address": "Ap #286-1581 Eleifend St.",
          "role":"Software Engineer",
          "country": "France",
          "text": "pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper,",
          "region": "Ross-shire",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Wyatt Parrish",
          "phone": "1-846-454-7485",
          "email": "orci.phasellus@hotmail.org",
          "address": "5660 Tempor Road",
          "role":"Software Engineer",
          "country": "Italy",
          "text": "est, vitae sodales nisi magna sed dui. Fusce aliquam, enim",
          "region": "Oyo",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Dennis Sullivan",
          "phone": "(256) 446-8745",
          "email": "ac.mattis@protonmail.edu",
          "address": "Ap #286-1581 Eleifend St.",
          "role":"Software Engineer",
          "country": "France",
          "text": "pellentesque massa lobortis ultrices. Vivamus rhoncus. Donec est. Nunc ullamcorper,",
          "region": "Ross-shire",
          "image":'https://source.unsplash.com/random/?man'
        },
        {
          "name": "Wyatt Parrish",
          "phone": "1-846-454-7485",
          "email": "orci.phasellus@hotmail.org",
          "address": "5660 Tempor Road",
          "role":"Software Engineer",
          "country": "Italy",
          "text": "est, vitae sodales nisi magna sed dui. Fusce aliquam, enim",
          "region": "Oyo",
          "image":'https://source.unsplash.com/random/?man'
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>