<template>
  <div class="flex flex-col h-full  w-full">
    <Search  :songs="songs"/> <!-- v-if="searchOpen"  -->
    <div class="flex flex-col m-1 rounded-md border shadow-inner overflow-y-auto" id="song" v-if="songs.length>0">
      <song-list-grid-view v-if="viewMode" :songs="songs" @onSongClicked="clickSong"/>
      <song-list-line-view v-else :songs="songs" @onSongClicked="clickSong"/>
    </div>
  </div>
</template>

<script>
import {GET_SONG_BY_ID, SCROLL_SONG_LIST_LEVEL, SET_OPEN_PANEL, SET_VERSE_INDEX, SONG} from "@/store/types";
import MainMenu from "./MainMenu";
import routehandler from "@/funcs/routehandler";
import Search from "@/components/Search";
import {GET_STATION_BY_ID, SET_CURRENT_STATION_ID} from "../store/types";
import SongListGridView from "./song/SongListGridView";
import SongListLineView from "./song/SongListLineView";

export default {
  name: "SongList",
  components: {SongListLineView, SongListGridView, MainMenu, Search},
  props: {
    addIcon: {
      type: Boolean,
      default: false
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    songs: {
      type: Array,
      required: true,
      default: []
    }
  },
  computed: {
    searchOpen() {
      return this.$store.state.searchOpen;
    },
    viewMode() {
      return this.$store.state.viewMode;
    }
  },
  methods: {
    clickSong(song) {
      if (!this.isRadio) {
        routehandler.addQueryParams(SONG, song.id);
        this.$store.dispatch(GET_SONG_BY_ID, song.id)
        this.$store.commit(SET_OPEN_PANEL, true);
        this.$store.commit(SET_VERSE_INDEX, -1);
        this.scrollSpy()
      } else {
        this.$store.dispatch(GET_STATION_BY_ID, song.id).then(res => {
          this.$store.commit(SET_CURRENT_STATION_ID, res.station.id)
          this.$store.commit(SET_OPEN_PANEL, true);
        })
      }
    },
    scrollSpy() {
      localStorage.setItem(SCROLL_SONG_LIST_LEVEL, document.getElementById("song").scrollTop.toString());
    },
  }
}
</script>

<style scoped>

</style>