<template>
  <Menu
      as="div">
    <div>
      <MenuButton
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm p-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
        <DotsVerticalIcon class="w-5 h-5 text-black"/>
      </MenuButton>
    </div>

    <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
      <MenuItems
          class="origin-top-right absolute right-0 mt-2 w-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="origin-top-right absolute right-4 lg:right-4  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 w-40">
          <MenuItem v-slot="{ active }">
            <div class="flex flex-col ">
              <button
                  @click="copyToClipboard()"
                  class="hover:bg-gray-100 rounded-tr-md rounded-tl-md text-gray-500 py-2 pl-4 md:text-sm text-xs flex flex-row space-x-3 border-b items-center"
              >
              <ClipboardIcon class="w-5 h-5 text-black"/>
                Copy
              </button>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" >
            <div class="flex flex-col ">
              <a
                  :href="'whatsapp://send?text='+songToShare"
                  class="hover:bg-gray-100 rounded-tr-md rounded-tl-md text-gray-500 py-2 pl-4 md:text-sm text-xs flex flex-row space-x-3 border-b items-center"
              >
              <ShareIcon class="w-5 h-5 text-black"/>
                Share
              </a>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {ClipboardIcon, DotsVerticalIcon, ShareIcon,} from "@heroicons/vue/outline";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {useToast} from "vue-toastification";

export default {
  name: 'DotsMenu',
  components: {
    DotsVerticalIcon,
    ShareIcon,
    ClipboardIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  props: {
    song: {
      type: Object,
      default: null,
      required: true
    },
  },
  setup() {
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    songToShare() {
      return this.title + this.song.verses
          .map((v) => {
            let result = v.isRefrain ? "REF: " : "";
            return result + v.line.replaceAll("\\n", "%0a");
          })
          .join("\n\n");
    },
    chord() {
      return this.song.mainChord ? " (" + this.song.mainChord + ")" : "";
    },
    title() {
      return this.song.number + ". " + this.song.name + this.chord + "\n\n";
    },
    verses() {
      return this.song.verses
          .map((v) => {
            let result = v.isRefrain ? "REF: " : "";
            return result + v.line.replaceAll("\\n", "");
          })
          .join("\n\n");
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.songToShare).then(() => {
        this.toast.success("Song has been copied to clipboard.");
      })
    },
    shareSongUrl() {
      if (this.song) {
        navigator.clipboard.writeText(document.location.href).then(() => {
          this.toast.success('Song URL has been copied to clipboard');
        }).catch((err) => {
          this.toast.error('Error copying the URL');
        })
      } else {
        this.toast.error('Please select a song');
      }
    },
  }
}
</script>