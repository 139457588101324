<template>
  <div
      v-if="isOpen"
      class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="container mx-auto px-4 py-8" v-if="uploadReport">
      <h2 class="text-2xl text-center font-bold mb-4">Upload Report</h2>
      <div v-if="uploadReport.total > 0" class="bg-white rounded shadow-md p-4">
        <div class="flex items-center justify-between mb-4">
          <div class="text-lg font-semibold">Total: {{ uploadReport.total }}</div>
          <div class="text-green-500">Success: {{ uploadReport.success }}</div>
          <div class="text-red-500">Error: {{ uploadReport.error }}</div>
        </div>
        <div v-for="item in uploadReport.report" :key="item.songbook" class="border-t py-2">
          <div class="text-lg font-semibold">{{ item.songbook }}</div>
          <div :class="isSuccess(item.status)?'text-green-500':'text-red-500'">{{ item.message }}</div>
          <div class="text-gray-500">Status: {{ item.status }}</div>
        </div>
      </div>
      <div v-else class="text-center">No upload report available.</div>
    </div>
    <div class="p-5 text-center">
      <button @click="onCloseModal" data-modal-hide="popup-modal" type="button"
              class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
        Close
      </button>

    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "UploadReport",
  props: {
    uploadReport: {
      type: Object,
      default: null
    },
  },
  watch: {
    uploadReport(newValue, oldValue) {
      if (newValue && newValue.total>1){

        console.log("newValue && newValue.total>1")
        console.log(newValue && newValue.total>1)
        this.isOpen=true
      }
    }
  },
  data() {
    return {
      isOpen: true
    }
  },
  methods: {
    isSuccess(item) {
      return item === 'CREATED' || item === 'UPDATED'
    },
    onCloseModal() {
      this.isOpen = false
    }
  },
})
</script>


<style scoped>

</style>