<template>
  <div class="bg-blue-500 flex-col  flex justify-center  items-center pr-4" style="height: var(--doc-height)">
    <div class="flex justify-center items-center h-full">
      <router-link :to="{name: 'DashboardEditSongPage'}"  class="bg-blue-900 text-white rounded px-2 py-3">Go to song detail </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "DashboardHomePage",

};
</script>
<style scoped>
</style>
