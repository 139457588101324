import Stomp from 'webstomp-client'
import SockJS from 'sockjs-client'
import * as type from "../store/types";
import {SET_PROJECTOR_STATE} from "../store/types";

export default {
    data() {
        return {
            response: "",
            stompClient: null,
            connected: false
        }
    },
    methods: {

        send(destination, data) {
            if (this.stompClient && this.stompClient.connected) {
                this.stompClient.send(destination, JSON.stringify(data), {})
            }
        },
        connect(room) {
            const serverURL = `${process.env.VUE_APP_URL}ws/`
            let socket = new SockJS(serverURL)
            this.stompClient = Stomp.over(socket)
            this.stompClient.connect(
                {},
                frame => {
                    this.connected = true
                    this.stompClient.subscribe(`/send/${room}`, res => {
                        this.response = JSON.parse(res.body)
                        this.$store.commit(type.SET_CURRENT_STREAM, this.response);
                    })
                },
                error => {
                    this.connected = false
                }
            )
        },
        disConnect() {
            if (this.stompClient != null) {
                this.stompClient.disconnect()
                this.$store.commit(type.SET_CURRENT_STREAM, {
                    verseIndex: 0,
                    song: null,
                    connected: this.stompClient && this.stompClient.connected
                });
            }
            this.$store.commit(SET_PROJECTOR_STATE, false)
            console.log('disConnected')
        },
    }
}
