import * as type from "../types";

export default {
    [type.TOGGLE](state, payload) {
        let url = payload.url;

        if (state.current.url === url) {
            state.current = {
                url: null,
                isStream: true
            };
        } else {
            state.current = payload;
        }
    },
    [type.START_PLAYING](state) {
        state.isPlaying = true;
    },
    [type.STOP_PLAYING](state) {
        state.isPlaying = false;
    }
}