import * as type from "../types";

export default {
    [type.GET_STATION_LIST]: ({commit, getters}) => {
        return getters.RADIO_GET(`/nowplaying`, commit)
            .then((data) => {
                if (data)
                    commit(type.GET_STATION_LIST, data);
                return data;
            })
    },
    [type.GET_STATION_BY_ID]: ({commit, getters}, stationId) => {
        return getters.RADIO_GET(`/nowplaying/${stationId}`, commit)
            .then((data) => {
                if (data)
                    commit(type.GET_STATION_BY_ID, data);
                return data;
            })
    }
};
