import {HTTP, ADMIN, RADIO} from "../axios";
import * as type from "./types";

const getters = {
    headers(state) {
        return {
            'Authorization': 'Bearer ' + state.sessions.access_token
        }
    },
    GET_WITH_LOADER(state, getters) {
        return (url, commit) => {
            commit(type.LOADER_ACTION, true);
            return HTTP.get(url, {headers: getters.headers}).then((response) => {
                commit(type.LOADER_ACTION, false);
                return response.data || {};
            }).catch((error) => {
                commit(type.LOADER_ACTION, false);
            });
        };
    },
    RADIO_GET_WITH_LOADER(state, getters) {
        return (url, commit) => {
            commit(type.LOADER_ACTION, true);
            return RADIO.get(url, {headers: getters.headers}).then((response) => {
                commit(type.LOADER_ACTION, false);
                return response.data || {};
            }).catch((error) => {
                commit(type.LOADER_ACTION, false);
            });
        };
    },

    RADIO_GET(state, getters) {
        return (url) => RADIO.get(url, {headers: getters.headers}).then((response) => response.data || {});
    },
    ADMIN_GET(state, getters) {
        return (url, commit) => {
            commit(type.LOADER_ACTION, true);
            return ADMIN.get(url, {headers: getters.headers}).then((response) => {
                commit(type.LOADER_ACTION, false);
                return response.data || {};
            }).catch((error) => {
                commit(type.LOADER_ACTION, false);
            });
        };
    },

    GET(state, getters) {
        return (url) => HTTP.get(url, {headers: getters.headers}).then((response) => response.data || {});
    },

    POST(state, getters) {
        return (url, params) => HTTP.post(url, params, {headers: getters.headers})
            .then(response => response.data || {}).catch(response => response.response.data || {})
    },

    ADMIN_POST(state, getters) {
        return (url, params) => ADMIN.post(url, params, {headers: getters.headers})
            .then(response => response.data || {}).catch(response => response.response.data || {})
    },

    GET_WITH_LOdADER(state, getters) {
        return (url, commit) => {
            commit(type.LOADER_ACTION, true);
            return HTTP.get(url, {headers: getters.headers}).then((response) => {
                commit(type.LOADER_ACTION, false);
                return response.data || {};
            }).catch((error) => {
                commit(type.LOADER_ACTION, false);
            });
        };
    },
    ADMIN_POST_LOADER(state, getters) {
        return (url, params, commit) => {
            commit(type.LOADER_ACTION, true);
            return ADMIN.post(url, params, {headers: getters.headers})
                .then(response => {
                    commit(type.LOADER_ACTION, false);
                    return response.data || {}
                }).catch(response => {
                    commit(type.LOADER_ACTION, false);
                    return response.response.data || {}
                })
        }
    },

};

export default getters;
