import * as type from "../types";

export default {
    [type.GET_FAVORITES]: (state, favorites) => {
        state.currentSongbook.favorite = true
        state.currentSongbook = favorites;
        state.songs = favorites.songs.map(song => {
            song.favorite = true
            return song
        })
    },
};
