// config
export const SET_OPEN_PANEL = "panelOpen";
export const SCROLL_SONG_LIST_LEVEL = "scrollSongListLevel";
export const TOAST = "toast";
export const SET_OPEN_EDIT_PANEL = 'openCloseEditPanel'
export const INITIALIZE_OPEN_EDIT_PANEL = 'initOpenCloseEditPanel'

export const SET_LAYOUT = 'setLayout'
export const CHANGE_VIEW_MODE = 'changeViewMode'

export const ON_ICON_SEARCH_CLICKED = 'onIconSearchClicked'

// Language
export const GET_LANGUAGES = "getLanguages";
export const GET_TRANSLATED_LANGUAGES = "getTranslatedLanguages";
export const GET_LANGUAGE = "getLanguage";
export const GET_LANGUAGES_BY_SONG = "getLanguagesBySong";
export const SET_CURRENT_TEMP_LANGUAGE = "setCurrentTempLanguage";
export const GET_CURRENT_LANGUAGE = "getCurrentLanguage";
export const LANGUAGE = "lang";

// Songbook
export const GET_SONGBOOKS = "getSongbooks";
export const ADMIN_GET_SONGBOOKS = "adminGetSongbooks";
export const GET_SONGBOOKS_BY_LANGUAGE = "getSongbooksByLanguage";
export const GET_SONGBOOK = "getSongbook";
export const UPLOAD_SONGBOOKS = "uploadSongbooks";
export const GET_SONGBOOKS_BY_LANGUAGE_ID = "getSongbooksByLanguageId";
export const GET_CURRENT_SONGBOOK = "getCurrentSongBook";
export const SONGBOOK = "songbook";
export const GET_SONG_CODES = "getSongCodes"
export const SET_MAIN_CHORDS = "setMainChords"

// Songs
export const GET_SONGS = "getSongs";
export const GET_SONGS_BY_SONGBOOK = "getSongsBySongbook";
export const GET_SONG_ANALOG = "getSongAnalog";
export const GET_SONG_BY_ID = "getSongById";

//stream
export const SET_CURRENT_STREAM = "setCurrentStream";
export const SET_ROOM_NUMBER = "setRoomNumber";
export const SET_PROJECTOR_STATE = "setProjectorState";
export const SET_VERSE_INDEX = "setVerseIndex";
export const SET_STREAMING_LINK = "setStreamingLink";
export const GET_LAST_STREAM_MESSAGE = "getLastStreamMessage";

export const LOADER_ACTION = "waitLoader";
export const SET_OPEN_MENU = "setOpenMenu";


export const SONG = "song";

//auth

export const GET_USER_ME = "getUserMe"
export const GET_USERS = "getUsers"
export const POST_REGISTER_USER = "postRegisterUser"
export const POST_UNGRANT_USER = "postUngrantUser"
export const SEND_AUTH = "postLogin"
export const POST_GRANT_USER = "postGrantUser"
export const LOGOUT = 'logout';
export const CHANGE_SESSION = 'changeSession';


// radio
export const GET_STATION_LIST = "getStationList"
export const GET_STATION_BY_ID = "getStationById"
export const STATION = "station"
export const SET_CURRENT_STATION_ID = "setCurrentStationId"


export const GET_FAVORITES = 'getFavorites';
export const LIKE_OR_DISLIKE = 'likeOrDislike';


export const TOGGLE = 'toggle';
export const START_PLAYING = 'startPlaying';
export const STOP_PLAYING = 'stopPlaying';