import * as type from "../types";

export default {
    [type.GET_SONGS_BY_SONGBOOK]: (state, songs) => {
        state.songs = songs.sort((a, b) => (a.number - b.number));
    },
    [type.SET_MAIN_CHORDS]: (state, songs) => {
        songs.forEach(song => {
            if (song.mainChord && !state.mainChords.some(ch => ch.trim().toLowerCase() === song.mainChord.trim().toLowerCase())) {
                state.mainChords.push(song.mainChord.trim())
            }
        })
    },

    [type.GET_SONGS]: (state, songs) => {
        state.songs = songs;
    },

    [type.GET_SONG_ANALOG]: (state, song) => {
        state.song = song;
    },

    [type.GET_SONG_BY_ID]: (state, song) => {
        state.song = song;
    },

    [type.GET_SONG_CODES]: (state, codes) => {
        state.songCodes = codes
    }
};
