<template>
  <div v-if="song" class="text-center flex justify-center items-center w-full h-full bg-gray-200 ">
    <audio :autoPlay="autoPlay" v-if="song.audioUrl" :loop="looping" ref="audio" :src="song.audioUrl"
           @timeupdate="update" @loadeddata="load"
           @pause="playing = false" @play="play" preload="auto" class="hidden"></audio>
    <div v-if="song"
         class="w-11/12 xl:w-9/12 flex flex-col items-center  bg-white  rounded-lg shadow-md py-10 relative">
      <div
          class="lg:hidden  absolute top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs bg-white w-5 h-5 bg-red-600 border-red-300 animate-pulse rounded-full">
      </div>
      <div class="w-full pb-4">
        <div class="flex items-center justify-center px-2 lg:px-10">
          <span class=" font-bold text-sm lg:text-md  uppercase mr-2">{{ song.currentMedia.name }}</span>
          <span
              class="lg:block hidden text-xs bg-red-600 border-red-300 rounded-full h-4 w-4 border-2 animate-pulse"> </span>
        </div>
      </div>
      <div class="flex lg:flex-row flex-col lg:justify-between w-full">
        <div class="w-full">
          <div class="flex flex-col justify-center items-center ">
            <div class="mb-8 ml-10 lg:ml-16">
              <svg class="-rotate-90" height='250' width='200'>
                  <pattern id="image" x="0%" y="0%"  patternUnits="userSpaceOnUse" width="100%" height="100%">
                    <image transform = "rotate(90 130 120)" x="0%" y="0%" width="100%" height="100%" preserveAspectRatio="xMinYMin slice" :href="song.currentMedia.image"></image>
                  </pattern>
                <circle
                    class="text-gray-300 "
                    stroke-width="8"
                    stroke="currentColor"
                    fill="url(#image)"
                    r="90"
                    cx="100"
                    cy="100"
                />
                <circle
                    class="text-[#5b5b5e]  "
                    stroke-width="8"
                    :stroke-dasharray="circumference"
                    :stroke-dashoffset="circumference - progressStyle / 100 * circumference"
                    stroke-linecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="90"
                    cx="100"
                    cy="100"
                />

              </svg>
            </div>
            <div class="relative w-full px-4 py-2 lg:mt-0 -mt-6">
              <div class="absolute left-4 -top-5 px-2">
                {{ time_display_played }}
              </div>
              <div v-if="playing" class="absolute left-0 right-0 -top-5 px-2">
                <div class="flex justify-center items-center gap-1 ">
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                  <div class="loader"></div>
                </div>
              </div>
              <div class="absolute right-4 -top-5 px-2">
                {{ timeDisplayTotal }}
              </div>
              <div class="z-20 flex justify-between shadow px-4 items-center space-x-2 rounded-md py-3">
                <div class="p-2 w-10 h-10 shadow flex items-center justify-center rounded-full bg-gray-700 text-white">
                <span v-if="!playing" class="cursor-pointer" id="play"
                      :title="(playing) ? 'Pause' : 'Play'">
                    <Icon @click.prevent="play" class="cursor-pointer" icon-name="PlayIcon" :icon-size="5"
                          icon-type="outline"/>
                  </span>
                  <span v-else id="stop" title="Stop">
                  <Icon :class="['cursor-pointer']" icon-name="PauseIcon" @click.prevent="pause" :icon-size="5"
                        icon-type="outline"/>
                  </span>
                </div>
                <div>
                <span class="flex items-center space-x-2">
                  <Icon v-if="!muted" icon-name="VolumeUpIcon" class="cursor-pointer" @click.prevent="mute"
                        :icon-size="5" icon-type="outline"/>
                  <Icon v-else icon-name="VolumeOffIcon" class="cursor-pointer" @click.prevent="mute" :icon-size="5"
                        icon-type="outline"/>
                  <input v-model.lazy.number="volume" :title="volumeTitle"
                         class="rounded-lg overflow-hidden appearance-none w-20 bg-gray-300 h-2   cursor-pointer"
                         type="range" min="0" max="100"/>
                </span>
                </div>
                <div class="flex  space-x-2 items-center px-2 rounded-md   border-dashed">
                  <span title="Listeners"><icon class="text-gray-300" icon-type="outline" icon-name="UserGroupIcon"
                                                :icon-size="5"/></span>
                  <span>{{ song.currentMedia.listeners.current }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="song" class="w-full text-sm px-4">
          <div class=" text-left w-full border my-2 p-2  rounded-md ">
            <div class="flex justify-between px-2 rounded-md">
              <span :title="song.nextMedia.name"
                    class="truncate">Next:&nbsp;&nbsp; {{ truncateName(song.nextMedia.name, 28) }} </span>
            </div>
<!--            <div class="flex justify-between px-2 rounded-md mt-1 pb-2 border-dashed">-->
<!--              <span title="Duration"><icon class="text-gray-300" icon-type="outline" icon-name="ClockIcon"-->
<!--                                           :icon-size="5"/></span>-->
<!--              <span>{{ unixTimestampToDate(song.nextMedia.playedAt) }}</span>-->
<!--            </div>-->
          </div>
          <div class="lg:block hidden text-left border my-2 p-2 rounded-md ">
            <h4 class="">Last played</h4>
            <div class="overflow-y-auto" style="max-height: calc(var(--main-content-admin-height) - 250px)">
              <div v-for="(md, index) in song.previewMedias" :key="index"
                   class="flex items-center  justify-between  border-b border-dashed  mb-1 rounded-md px-2 py-2">
                <span :title="md.name" class=" whitespace-nowrap">{{ truncateName(md.name, 21) }}</span>
                <span>{{ unixTimestampToDate(md.playedAt) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../common/HeroIcon";
import utils from "../../funcs/utils";
import {DateTime} from 'luxon';

export default {
  name: "RadioPlayer",
  components: {Icon},
  props: {
    autoPlay: {
      type: Boolean,
      default: false
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    displayPanel: {
      type: Boolean,
      default: () => false
    },
    song: {
      type: Object,
      default: null
    },
    loop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      'np_elapsed': 0,
      currentSeconds: 0,
      durationSeconds: 0,
      loaded: false,
      looping: false,
      playing: false,
      previousVolume: 35,
      showVolume: false,
      volume: 100,
      'clock_interval': null
    }
  },

  created() {
    this.looping = this.loop;
  },

  watch: {
    playing(value) {
      if (value) {
        return this.$refs.audio.play();
      }
      if (this.$refs.audio)
        this.$refs.audio.pause();

    },
    volume() {
      this.$refs.audio.volume = this.volume / 100;
    }
  },
  mounted() {
    this.clock_interval = setInterval(this.iterateTimer, 1000);
    // if (this.autoplay) {
    //   this.switchStream(this.current_stream);
    // }
  },

  computed: {
    circumference() {
      return 90 * 2 * Math.PI
    },

    songUrl() {
      let songUrl = null
      let song = this.song
      if (song && song.audio && !this.isRadio) {
        songUrl = process.env.VUE_APP_SONG_URL + song.audioUrl
      } else {
        songUrl = song.audioUrl
      }
      return songUrl;
    },

    muted() {
      return this.volume / 100 === 0;
    },

    volumeTitle() {
      return `Volume (${this.volume}%)`;
    },

    timeDisplayTotal() {
      let time_total = this.song.currentMedia.duration;
      return (time_total) ? this.formatTime(time_total) : null;
    },

    time_display_played() {
      let time_played = this.np_elapsed;
      let time_total = this.song.currentMedia.duration;
      if (!time_total) {
        return null;
      }
      if (time_played > time_total) {
        time_played = time_total;
      }
      return this.formatTime(time_played);
    },

    progressStyle() {
      let time_played = this.np_elapsed;
      let time_total = this.song.currentMedia.duration;
      if (!time_total) {
        return 0;
      }
      if (time_played > time_total) {
        return 100;
      }
      return (time_played / time_total) * 100;
    },
  },

  methods: {

    truncateName(str, lenght) {
      return utils.truncateString(str, lenght)
    },

    iterateTimer() {
      let current_time = Math.floor(Date.now() / 1000);
      let np_elapsed = current_time - this.song.currentMedia.playedAt;
      if (np_elapsed < 0) {
        np_elapsed = 0;
      } else if (np_elapsed >= this.song.currentMedia.duration) {
        np_elapsed = this.song.currentMedia.duration;
      }
      this.np_elapsed = np_elapsed;
    },

    unixTimestampToDate(timestamp) {
      if (!timestamp) {
        return '';
      }
      return DateTime.fromSeconds(timestamp).toRelative();
    },


    formatTime(time) {
      let sec_num = parseInt(time, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      return (hours !== '00' ? hours + ':' : '') + minutes + ':' + seconds;
    },

    convertTimeHHMMSS(val) {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },

    play() {
      this.playing = true
    },

    load() {
      if (this.$refs.audio.readyState >= 2) {
        this.loaded = true;
        this.durationSeconds = parseInt(this.$refs.audio.duration);
        return this.playing = this.autoPlay;
      }
      throw new Error('Failed to load sound song.');
    },

    mute() {
      if (this.muted) {
        return this.volume = this.previousVolume;
      }
      this.previousVolume = this.volume;
      this.volume = 0;
    },

    stop() {
      this.playing = false;
      if (this.$refs.audio) {
        this.$refs.audio.currentTime = 0;
      }
    },

    pause() {
      this.playing = false;
      if (this.$refs.audio) {
        this.$refs.audio.pause();
      }
    },

    update() {
      if (this.$refs.audio)
        this.currentSeconds = parseInt(this.$refs.audio.currentTime);
    }
  },
}
</script>

<style scoped>
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background: #ffffff;
  box-shadow: -405px 0 0 400px #5b5b5e;
  border-radius: 50%;
}


.loader {
  width: 6px;
  height: 4px;
  border-radius: 5px;
  background-color: #fff;
  animation: playingmusic 1s ease-in-out infinite;

}

@keyframes playingmusic {
  0% {
    height: 4px;
  }
  50% {
    height: 20px;
  }
  100% {
    height: 4px;
  }

}

.loader:nth-child(1) {
  background-color: rgba(124, 124, 141, 0.98);
  animation-delay: 1s;
}

.loader:nth-child(2) {
  background-color: rgba(192, 192, 192, 0.71);
  animation-delay: 0.8s;
}

.loader:nth-child(3) {
  background-color: rgb(107, 114, 128);
  animation-delay: 0.6s;
}

.loader:nth-child(4) {
  background-color: rgba(192, 192, 192, 0.71);
  animation-delay: 0.4s;
}

.loader:nth-child(5) {
  background-color: rgba(124, 124, 141, 0.98);
  animation-delay: 0.2s;
}

.loader:nth-child(6) {
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  animation-delay: 0.4s;
}

.loader:nth-child(7) {
  background-color: rgba(192, 192, 192, 0.71);
  animation-delay: 0.4s;
}

.loader:nth-child(8) {
  background-color: rgb(107, 114, 128);
  animation-delay: 0.6s;
}

.loader:nth-child(9) {
  background-color: rgba(192, 192, 192, 0.71);
  animation-delay: 0.8s;
}

.loader:nth-child(10) {
  background-color: rgba(124, 124, 141, 0.98);
  animation-delay: 1s;
}
</style>