import {SCROLL_SONG_LIST_LEVEL} from "@/store/types";
import {SONGBOOK} from "../store/types";

export default {
    addQueryParams(key, value) {
        if (history.pushState) {
            let newUrl = document.location.href
            if (key === SONGBOOK) {
                newUrl = newUrl.substring(0, newUrl.indexOf('?'))
            }
            if (document.location.href.indexOf('?') === -1) {
                if (value)
                    newUrl += `?${key}=${value}`;
            } else {
                if (newUrl.indexOf(key)) {
                    newUrl = updateQueryStringParameter(newUrl, key, value)
                } else {
                    newUrl += `&${key}=${value}`;
                }
            }
            window.history.pushState({path: newUrl}, '', newUrl);
        }
    },
    spyScrollSongListPanel(y) {
        document.getElementById("song").scrollTo({
            top: y
        })
    },

    initScroll() {
        if (window.location.href.indexOf('?') === -1) {
            localStorage.removeItem(SCROLL_SONG_LIST_LEVEL)
        }
    },
}

function updateQueryStringParameter(uri, key, value) {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        if (!value) {
            return uri.replace(re, '');
        }
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        if (value) {
            return uri + separator + key + "=" + value;
        }
    }
}
