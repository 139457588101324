<template>
  <div v-if="song && song.audio" :class="['flex items-center w-full']"
       style="height: var(--player-block-height)">
    <audio :loop="looping" ref="audio" :src="songUrl" @timeupdate="update" @loadeddata="load"
           @pause="playing = false" @play="play" preload="auto" class="hidden"></audio>
    <div class="w-20 h-14 rounded bg-black px-2  ml-2">
      <img src="@/assets/logo.png" class="object-cover w-full h-full  ">
    </div>
    <div class="w-full px-8 py-2">
      <div class="time-progress-bar-block">
        <div class="flex justify-between items-center w-full">
          <span>{{ convertTimeHHMMSS(this.currentSeconds) }}</span>
          <div class="w-full mx-5 ">
            <div class="progress-bar" @click="seek">
              <div :style="progressStyle" class="progress-bar-seek" title="Seek"></div>
            </div>
          </div>
          <span>{{ convertTimeHHMMSS(this.durationSeconds) }}</span>
        </div>
      </div>
      <div class="btn-actions-container mt-2">
        <div class="flex flex-row space-x-4 items-center">
          <span v-if="!playing" class="btn-icon" id="play" @click.prevent="playing = !playing"
                :title="(playing) ? 'Pause' : 'Play'">
              <Icon class="cursor-pointer" icon-name="PlayIcon" :icon-size="5" icon-type="outline"/>
            </span>
          <span v-if="playing" class="btn-icon" id="stop" @click.prevent="pause" title="Stop">
            <Icon :class="['cursor-pointer']" icon-name="PauseIcon" :icon-size="5" icon-type="outline"/>
            </span>
          <span @click.prevent="rewind" class="btn-icon hidden lg:flex">
              <Icon class="cursor-pointer" icon-name="RewindIcon" :icon-size="5" icon-type="outline"/>
            </span>
          <span @click="fastForward" class="btn-icon hidden lg:flex">
            <Icon icon-name="FastForwardIcon" :icon-size="5" icon-type="outline"/>
            </span>
          <span class="relative mt-1 btn-icon">
            <Icon @click.prevent="looping = !looping" icon-name="RefreshIcon" :icon-size="5" icon-type="outline"
                  :class="[looping ? 'text-purple-500': '' ]"/>
              <span v-if="looping" class="is-looping-audio"></span>
            </span>
          <span id="download" @click.prevent="download" class="btn-icon">
              <Icon class="cursor-pointer" icon-name="DownloadIcon" :icon-size="5" icon-type="outline"/>
            </span>
          <div id="volume" @mouseenter="showVolume = true">
            <a class="relative space-x-2"
               :title="volumeTitle" href="#">
            <span @click.prevent="mute" class="absolute -inset-y-2.5 btn-icon">
            <Icon v-if="!muted" icon-name="VolumeUpIcon" :icon-size="5" icon-type="outline"/>
            <Icon v-else icon-name="VolumeOffIcon" :icon-size="5" icon-type="outline"/>
            </span>
            </a>
          </div>
          <span class="relative left-3" @mouseleave="showVolume = false">
              <input v-model.lazy.number="volume" v-show="showVolume"
                     class="volume-range"
                     type="range"
                     min="0"
                     max="100"/>
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayerBar",
  props: {
    autoPlay: {
      type: Boolean,
      default: false
    },
    isRadio: {
      type: Boolean,
      default: false
    },
    displayPanel: {
      type: Boolean,
      default: () => false
    },
    song: {
      type: Object,
      default: null
    },
    loop: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentSeconds: 0,
      durationSeconds: 0,
      loaded: false,
      looping: false,
      playing: false,
      previousVolume: 35,
      showVolume: false,
      volume: 100
    }
  },
  computed: {
    songUrl() {
      let songUrl = null
      let song = this.song
      if (song && song.audio && !this.isRadio) {
        songUrl = process.env.VUE_APP_SONG_URL + song.audioUrl
      } else {
        songUrl = song.audioUrl
      }
      return songUrl;
    },
    progressBar() {
      return {
        'width': 20 + '%'
      }
    },
    muted() {
      return this.volume / 100 === 0;
    },
    percentComplete() {
      return parseInt(this.currentSeconds / this.durationSeconds * 100);
    },
    progressStyle() {
      return {width: `${this.percentComplete}%`};
    },
    volumeTitle() {
      return `Volume (${this.volume}%)`;
    }
  },

  created() {
    this.looping = this.loop;
  },

  filters: {},

  watch: {
    playing(value) {
      if (value) {
        return this.$refs.audio.play();
      }
      if (this.$refs.audio)
        this.$refs.audio.pause();

    },
    volume() {
      this.$refs.audio.volume = this.volume / 100;
    }
  },
  methods: {
    convertTimeHHMMSS(val) {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },
    play() {
      this.playing = true
    },
    download() {
      this.stop();
      window.open(this.songUrl, 'download');
    },
    switchAudio() {
      this.stop();
      // window.open(this.song.mainUrl, 'download');
    },
    load() {
      if (this.$refs.audio.readyState >= 2) {
        this.loaded = true;
        this.durationSeconds = parseInt(this.$refs.audio.duration);
        return this.playing = this.autoPlay;
      }
      throw new Error('Failed to load sound song.');
    },
    mute() {
      if (this.muted) {
        return this.volume = this.previousVolume;
      }
      this.previousVolume = this.volume;
      this.volume = 0;
    },
    seek(e) {
      if (!this.loaded) return;
      const bounds = e.target.getBoundingClientRect();
      const seekPos = (e.clientX - bounds.left) / bounds.width;
      this.$refs.audio.currentTime = parseInt(this.$refs.audio.duration * seekPos);
    },
    rewind() {
      if (this.currentSeconds > 0) {
        this.$refs.audio.currentTime -= parseInt(this.$refs.audio.duration * 0.05);
      }
    },
    fastForward() {
      if (this.currentSeconds < this.durationSeconds) {
        this.$refs.audio.currentTime += parseInt(this.$refs.audio.duration * 0.05);
      }
    },
    stop() {
      this.playing = false;
      if (this.$refs.audio) {
        this.$refs.audio.currentTime = 0;
      }
    },
    pause() {
      this.playing = false;
      if (this.$refs.audio) {
        this.$refs.audio.pause();
      }
    },
    update() {
      if (this.$refs.audio)
        this.currentSeconds = parseInt(this.$refs.audio.currentTime);
    }
  },
};
</script>
<style scoped>
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background: #ffffff;
  box-shadow: -405px 0 0 400px #8b5cf6;
  border-radius: 50%;
}
</style>