import {createStore} from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import state from "./state";
import getters from "@/store/getters";

export default createStore({
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
  modules: {},
  plugins: [],
  strict: false,
  devtools: true,
});
