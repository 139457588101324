import axios from "axios";

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_URL,
});

export const ADMIN = axios.create({
    baseURL: process.env.VUE_APP_ADMIN,
});

export const RADIO = axios.create({
    baseURL: process.env.VUE_APP_RADIO,
});


