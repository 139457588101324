import * as type from "../types";

let defaults = {
    type: 'success',
    time: 3000,
    text: '',
    delay: 200
}
export default {

    [type.TOAST]: ({commit}, options) => {
        options = {...defaults, ...options}
        setTimeout(() => {
            commit(type.TOAST, options)
            setTimeout(() => {
                commit(type.TOAST, null)
            }, options.time)
        }, options.delay)
    },

    [type.INITIALIZE_OPEN_EDIT_PANEL]: ({commit}) => {
        commit(type.INITIALIZE_OPEN_EDIT_PANEL)
    },

    [type.SET_OPEN_EDIT_PANEL]: ({commit}, payload) => {
        commit(type.SET_OPEN_EDIT_PANEL, payload)
    },

}
