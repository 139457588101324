<template>
  <Loader v-if="waitLoader"/>
  <component v-bind:is="layout"></component>
</template>

<script>
import Loader from "@/components/Loader";
import MainLayout from "./layouts/MainLayout";
import SingleLayout from "./layouts/SingleLayout";
import {SET_LAYOUT} from "./store/types";
import utils from "@/funcs/routehandler";


export default {
  name: "App",
  components: {
    'single-layout': SingleLayout,
    'main-layout': MainLayout,
    Loader
  },

  data() {
    return {}
  },

  beforeCreate() {
    // this.init()
    utils.initScroll();
  },
  watch: {
    '$route'(to, from) {
      if (['Login', 'Register', 'Profile', 'StreamPage','Contributors','UploadPage'].some(t => t === this.$route.name)) {
        this.$store.commit(SET_LAYOUT, 'single-layout')
      } else {
        this.$store.commit(SET_LAYOUT, 'main-layout')
      }
    }
  },
  beforeUpdate() {
    this.init()
  },
  computed: {
    layout() {
      return this.$store.state.layout
    },
    waitLoader() {
      return this.$store.state.waitLoader
    },
  },

  methods: {
    init() {
      if (document.location.pathname !== "/") {
        this.$checkAuth()
      }
    }
  }


}

</script>


