import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/client/home/Home.vue";
import AsideBlock from "../components/AsideBlock";
import UserBar from "../components/UserBar";
import DashboardEditSongPage from "../views/admin/DashboardEditSongPage";
import DashboardHomePage from "../views/admin/DashboardHomePage";
import AdminHomeAsideBlock from "../components/admin/aside/AdminHomeAsideBlock";
import AdminEditAsideBlock from "../components/admin/aside/AdminEditAsideBlock";
import ToolBar from "../components/ToolBar";
import Login from "../views/client/auth/Login";
import Register from "../views/client/auth/Register";
import TestPage from "../views/TestPage";
import Profile from "../views/client/account/Profile";
import RadioPage from "../views/client/radio/RadioPage";
import StreamPage from "../views/client/stream/StreamPage";
import FavoritePage from "../views/client/home/FavoritePage";
import Contributors from "../views/client/contributors/Contributors";
import UploadPage from "@/views/admin/UploadPage.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        components: {default: Home, asideBlock: AsideBlock, userBar: UserBar, toolbar: ToolBar},
    },
    {
        path: "/root",
        name: "DashboardHomePage",
        components: {default: DashboardHomePage, asideBlock: AdminHomeAsideBlock},
    },
    {
        path: "/test",
        name: "TestPage",
        component: TestPage,
    },
    {
        path: "/root/edit",
        name: "DashboardEditSongPage",
        components: {default: DashboardEditSongPage, asideBlock: AdminEditAsideBlock, userBar: UserBar},
    },
    {
        path: "/upload",
        name: "UploadPage",
        components: {default: UploadPage},
    },

    {
        path: "/user/profile",
        name: "Profile",
        components: {default: Profile, asideBlock: AsideBlock, userBar: UserBar, toolbar: ToolBar},
    },
    {
        path: "/stream/:room",
        name: "StreamPage",
        component: StreamPage,
    },
    {
        path: "/favorite",
        name: "FavoritePage",
        components: {default: FavoritePage, asideBlock: AsideBlock, userBar: UserBar, toolbar: ToolBar}
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/contributors",
        name: "Contributors",
        component: Contributors,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/radio",
        name: "RadioPage",
        components: {default: RadioPage, asideBlock: AsideBlock, userBar: UserBar, toolbar: ToolBar},
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
