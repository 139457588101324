<template>
  <main-menu/>

  <div id="small-modal" tabindex="-1" v-if="readQrCode"
       :class=" ['overflow-y-auto overflow-x-hidden flex justify-center items-center bg-[#000000b0] fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full']"
       style="height: var(--doc-height)">
    <div class="relative p-4 ">
      <div class="relative bg-white rounded-lg shadow ">
        <div class="flex justify-between items-center  dark:border-gray-600">
          <button type="button"
                  class="text-gray-400 bg-transparent
                  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
            <Icon @click.stop="readQrCode=!readQrCode" color="text-black"
                  class="cursor-pointer hover:text-red-900"
                  icon-name="XIcon"
                  :iconSize="6" icon-type="outline"/>
          </button>
        </div>
        <div class="p-6 space-y-6">
          <qr-code-reader/>
        </div>
      </div>
    </div>
  </div><!-- Small Modal -->

  <div class="h-full">
    <div class="py-4 w-full border-b px-2" style="height: var(--toolbar-header-block-height)">
      <div class="flex justify-between  items-center">
        <router-link v-if="displayHomeIcon" :to="{name: 'Home'}" class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon class="cursor-pointer" icon-name="HomeIcon" :icon-size="5" icon-type="outline"/>
        </router-link>
        <button v-else @click.prevent="onClickEvent()" class="p-2 border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon class="cursor-pointer" icon-name="MenuIcon" :icon-size="5" icon-type="outline"/>
        </button>
        <span class="lg:text-sm sm:text-[12px] px-1 font-bold flex justify-center items-center break-words w-full"
              v-if="currentSongbook">
          <span>{{ currentSongbook.name }}</span>
          <span class="lg:hidden flex h-3 w-3 ml-2" v-if="isProjecting">
            <span class="absolute rounded-full inline-flex bg-red-600 h-3 w-3 animate-ping opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
          </span>
        </span>
        <button @click="readQrCode=!readQrCode" class="p-2 mr-2 lg:hidden border
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon color="text-gray-700" class="cursor-pointer"
                icon-name="QrcodeIcon" :icon-size="5" icon-type="solid"/>
        </button>
        <button @click="onGridViewClicked"
                v-if="currentSongbook.code!=='radio_streaming'"
                class="p-2 mr-2 border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
          <Icon class="cursor-pointer" :icon-name="viewMode?'DocumentTextIcon':'ViewGridIcon'" :icon-size="5"
                icon-type="outline"/>
        </button>
<!--        <button v-if="!isRadio" @click="onIconSearchClicked"-->
<!--                class="p-2 border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">-->
<!--          <Icon class="cursor-pointer" icon-name="SearchIcon" :icon-size="5" icon-type="outline"/>-->
<!--        </button>-->
      </div><!---->
    </div>
    <div style="height: var(--left-aside-block)">
      <song-list :songs="songs" :isRadio="isRadio"/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import SongList from "./SongList";
import {CHANGE_VIEW_MODE, ON_ICON_SEARCH_CLICKED} from "../store/types";
import templateSetting from "@/mixins/templateSetting";
import MainMenu from "./MainMenu";
import QrCodeReader from "@/components/qrcode/QrCodeReader";

export default {
  name: "AsideBlock",
  mixins: [templateSetting],
  components: {QrCodeReader, MainMenu, SongList},
  data() {
    return {
      readQrCode: false
    }
  },
  computed: {
    ...mapState(["songs", "currentSongbook"]),
    displayHomeIcon() {
      return this.isRadio || this.$route.name === 'FavoritePage'
    },
    currentRouteName() {
      return this.$route.name;
    },
    isRadio() {
      return this.currentSongbook && this.currentSongbook.hasOwnProperty('isRadio')
    },
    isProjecting() {
      return this.$store.state.isProjecting;
    },
    viewMode() {
      return this.$store.state.viewMode;
    }
  },

  methods: {
    onIconSearchClicked() {
      this.$store.commit(ON_ICON_SEARCH_CLICKED, !this.$store.state.searchOpen)
      setTimeout(() => {
        const searchField = document.getElementById("searchField")
        if (searchField)
          searchField.focus();
      }, 5);
    },
    onGridViewClicked() {
      this.$store.commit(CHANGE_VIEW_MODE, !this.viewMode)
    },
  },
};
</script>

<style scoped>
</style>
