import * as type from "../types";
import stationToSong from "../../model/stationDto";

export default {
    [type.GET_STATION_LIST]: (state, stations) => {
        state.currentSongbook = {
            id: 400,
            name: "Radio station",
            code: "radio_streaming",
            isRadio: true,
            userId: 3,
            addedBy: "SYSTEM",
            description: null,
            languageId: 13,
            isDraft: false,
            version: "v0.0.1",
            language: {
                id: 13,
                name: "Default",
                code: "de"
            }
        }
        state.songs = stations.filter(station => station.station.name !== 'Riga' && station.playing_next).map(station => stationToSong(station))
    },


    [type.GET_STATION_BY_ID]: (state, station) => {
        state.song = stationToSong(station);
    },
    [type.SET_CURRENT_STATION_ID]: (state, currentStationId) => {
        state.currentStationId = currentStationId;
    },

}