import * as type from "../types";

export default {
    [type.SET_CURRENT_STREAM]: (state, stream) => {
        state.stream = stream;
    },
    [type.SET_ROOM_NUMBER]: (state, roomNumber) => {
        localStorage.setItem(type.SET_ROOM_NUMBER, roomNumber)
        state.roomNumber = roomNumber;
    },
    [type.SET_PROJECTOR_STATE]: (state, isProjecting) => {
        state.isProjecting = isProjecting;
    },
    [type.SET_VERSE_INDEX]: (state, verseIndex) => {
        state.stream.verseIndex = verseIndex;
    },
    [type.SET_STREAMING_LINK]: (state, link) => {
        state.streamingLink = link;
    },
    [type.GET_LAST_STREAM_MESSAGE]: (state, lastMessage) => {
        state.stream = lastMessage;
    },
};