import * as type from "../types";

export default {
    [type.GET_SONGS_BY_SONGBOOK]: ({commit, getters}, songbookId) =>
        getters.GET_WITH_LOADER(`/songs/web/songbook/${songbookId}`, commit)
            .then((data) => {
                if (data)
                    commit(type.SET_MAIN_CHORDS, data);
                    commit(type.GET_SONGS_BY_SONGBOOK, data);
                return data;
            }),

    [type.GET_SONG_CODES]: ({commit, getters}) =>
        getters.GET_WITH_LOADER(`/admin/codes`, commit)
            .then((data) => {
                if (data)
                    commit(type.GET_SONG_CODES, data);
                return data;
            }),


    [type.GET_SONG_ANALOG]: ({commit, getters}, {songCode: songCode, langCode: langCode, songbookId: songbookId}) =>
        getters.GET(`/songs/analog/${songCode}?langCode=${langCode}&songbookId=${songbookId}`, commit)
            .then((data) => {
                if (data)
                    commit(type.GET_SONG_ANALOG, data);
                return data;
            }),


    [type.GET_SONG_BY_ID]: ({commit, getters}, songId) =>
        getters.GET_WITH_LOADER(`/songs/${songId}`, commit)
            .then((data) => {
                if (data)
                    commit(type.GET_SONG_BY_ID, data);
                return data;
            }),

};
