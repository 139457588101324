<template>
  <div v-if="song" class="py-4 w-full border-b px-2" style="height: var(--toolbar-header-block-height)">
    <div class="flex justify-between items-center">
      <button @click="closeDisplayPanelRadio" class="p-2 border lg:invisible
              border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
        <Icon class="cursor-pointer" icon-name="ArrowLeftIcon" :icon-size="5" icon-type="outline"/>
      </button>
      <div class="flex py-2  w-full space-x-4 items-center">
        <span class="lg:text-lg font-bold  sm:text-[12px] break-words w-full text-center" v-if="song">
         {{ song.name }} </span>
      </div>
    </div><!---->
  </div>
  <div class="bg-gray-200 flex justify-center items-center w-full h-full">
    <radio-player :song="song" v-if="song"/>
    <welcome-panel v-else/>
  </div>
</template>

<script>
import RadioPlayer from "@/components/radio/RadioPlayer";
import {
  GET_SONG_ANALOG,
  GET_STATION_BY_ID,
  GET_STATION_LIST,
  SET_CURRENT_STATION_ID,
  SET_OPEN_PANEL, SONG,
  STATION
} from "../../../store/types";
import templateSetting from "../../../mixins/templateSetting";
import WelcomePanel from "../../../components/WelcomePanel";
import routehandler from "@/funcs/routehandler";
import utils from "@/funcs/routehandler";
export default {
  name: "RadioPage",
  components: {WelcomePanel, RadioPlayer},
  mixins: [templateSetting],
  data() {
    return {
      intervalToClear: null
    }
  },
  beforeCreate() {
    this.$store.dispatch(GET_STATION_LIST).then(res => {
      if (this.$route.query.station) {
        this.$store.commit(SET_OPEN_PANEL, true)
        this.$store.dispatch(GET_STATION_BY_ID, this.$route.query.station)
      } else {
        this.$store.commit(SET_OPEN_PANEL, false)
      }
    })
  },
  mounted() {
    this.setIntervalOfRadio();
  },
  beforeUnmount() {
    clearInterval(this.intervalToClear)
    this.$store.commit(SET_CURRENT_STATION_ID, null)
  },

  watch: {
    currentStationId(newVal) {
      clearInterval(this.intervalToClear)
      routehandler.addQueryParams(STATION, newVal);
      this.setIntervalOfRadio(this.currentStationId);
    }
  },
  computed: {
    song() {
      return this.$store.state.song;
    },
    currentStationId() {
      return this.$store.state.currentStationId;
    }
  },

  methods: {
    setIntervalOfRadio() {
      let that = this
      if (that.currentStationId) {
        that.intervalToClear = setInterval(function () {
          that.$store.dispatch(GET_STATION_BY_ID, that.currentStationId)
        }, 3000)
      }
    },
    closeDisplayPanelRadio() {
      this.$store.commit(SET_OPEN_PANEL, false);
      utils.addQueryParams(STATION, null);
    },

  },

}
</script>

<style scoped>

</style>