import * as type from "../types";

export default {
    [type.GET_LANGUAGES]: (state, languages) => {
        state.languages = languages;
    },

    [type.GET_LANGUAGE]: (state, language) => {
        state.language = language;
    },

    [type.SET_CURRENT_TEMP_LANGUAGE]: (state, language) => {
        state.currentTempLanguage = language;
    },

    [type.GET_TRANSLATED_LANGUAGES]: (state, languages) => {
        if (state.song) { //TODO this should be change
            state.translatedLanguages = languages.filter(language => language.name !== state.song.language.name);
        }
    },
};
