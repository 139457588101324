import * as type from "../types";

export default {
    [type.GET_LANGUAGES]: ({commit, getters}) =>
        getters.GET(`/languages`).then((data) => {
                if (data)
                    commit(type.GET_LANGUAGES, data)
            }
        ),

    [type.GET_TRANSLATED_LANGUAGES]: ({commit, getters}, code) =>
        getters.GET(`/languages/song/${code}`).then((data) => {
                if (data)
                    commit(type.GET_TRANSLATED_LANGUAGES, data)
            }
        ),

};
