<template>
  <div class="fixed top-0 left-0 w-full h-full loader-wrap">
    <div class="fixed w-full h-full top-0 left-0"></div>
    <div
        class="absolute w-full h-full top-0 left-0 flex justify-center items-center flex-col z-50"
    >
      <div class="lds-roller relative">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div><span class="absolute left-[35%] top-[40%]">{{loadingPercent}}%</span>
      </div>
      <div class="text-gray-500 block mt-3">{{ labelText }}</div>
    </div>
  </div>
</template>

<script>
import PageLoadProgress from "../mixins/PageLoadProgress";

export default {
  name: "Loader",
  mixins:[PageLoadProgress],
  props: {
    labelText: {
      type: String,
      default: 'Loading'
    }
  },
  data() {
    return {
      count: 0
    }
  },
  mounted() {
    setInterval(()=> this.count++, 1000)
  }
};
</script>

<style scoped>
.loader-wrap {
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
}

.lds-roller {
  display: block;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #777;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
