import {GET_SONG_ANALOG, LANGUAGE, SET_OPEN_MENU, SET_OPEN_PANEL, SONG, SONGBOOK} from "../store/types";
import utils from "@/funcs/routehandler";

export default {
    watch: {
        $route(ne) {
            if (isNaN(parseInt(ne.query.song))) {
                this.closeDisplayPanel();
            }
        },
    },
    methods: {
        onClickEvent() {
            this.$store.commit(SET_OPEN_MENU, !this.open);
        },
        initQueryParams(songBook) {
            utils.addQueryParams(SONGBOOK, songBook.id);
            utils.addQueryParams(LANGUAGE, songBook.language.id);
        },
        closeDisplayPanel() {
            this.$store.commit(SET_OPEN_PANEL, false);
            this.$store.commit(GET_SONG_ANALOG, null);
            utils.addQueryParams(SONG, null);
        },
    }
}