<template>
  <div class="flex flex-col space-y-3">
    <span v-if="label.length>0" class="text-sm font-medium text-gray-500">{{ label }}: </span>
    <div>
      <div class="relative w-full">
        <div class="block">
          <input @click.prevent="onShowSelect"
                 v-model="searchText"
                 class="rounded-md w-full pl-12 border-gray-300 bg-white py-2.5 text-sm outline-none border"/>
          <Icon v-if="showSelect" class="absolute inset-y-3 ml-2 flex-shrink-0 h-5 w-5 text-gray-500"
                icon-name="SearchIcon" :icon-size="5" icon-type="outline"/>
          <Icon v-else class="absolute inset-y-3 ml-2 flex-shrink-0 h-5 w-5 text-gray-500" icon-name="GlobeIcon"
                :icon-size="5" icon-type="outline"/>
          <button
              @click.prevent="onShowSelect"
              type="button"
              class="absolute inset-y-3 right-2 flex items-center justify-center w-6 h-6">
            <Icon class="h-5 w-5 text-gray-400" icon-name="SelectorIcon" aria-hidden="true" :icon-size="5"
                  icon-type="outline"/>
          </button>
        </div>
        <div :class="showSelect ? 'ring-1 ring-black ring-opacity-5' : ''"
             class="absolute rounded-md mt-1 shadow-xl bg-white focus:outline-none z-50 w-full"
             @mouseleave="showSelect = false">
          <div v-show="showSelect" class="relative">
            <div class="w-full rounded-lg bg-white">
              <div class="mt-1 z-50 bg-white rounded-bl-lg text-left">
                <div
                    data-simplebar
                    class="overflow-x-hidden h-auto max-h-40 overflow-y-auto w-full rounded-md">
                  <div v-if="filteredLanguages() && filteredLanguages().length>0">
                    <div
                        class="flex flex-col text-gray-700 text-sm"
                        v-for="item in filteredLanguages()"
                        :key="item.id">
                      <a href="#" class="px-3 py-2 hover:text-white hover:bg-indigo-600 flex justify-between"
                         @click.prevent="onClick(item)">
                        <span :class=" selectedItem && selectedItem.id === item.id ? 'font-bold ' : ''  ">
                          {{ item.name }}</span>
                        <Icon v-if="selectedItem && selectedItem.id === item.id" icon-name="CheckIcon"
                              aria-hidden="true" :icon-size="5" icon-type="outline"/>
                      </a>
                    </div>
                  </div>
                  <div v-else class="flex flex-col justify-center items-center h-40">
                    <Icon icon-name="DocumentSearchIcon" class="text-gray-200" aria-hidden="true" :icon-size="10"
                          icon-type="outline"/>
                    <span class="text-sm text-gray-400">{{ notFoundMessage }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CustomSelect",
  props: {
    notFoundMessage: {
      type: String,
      default: () => "Sorry! The search language has not been found..."
    },
    label: {
      type: String,
      default: () => ""
    },
    selectedItem: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      searchText: "",
      showSelect: false,
    };
  },

  mounted() {
    let selected = this.selectedItem
    if (selected) {
      this.searchText = selected.name;
    }
  },

  watch: {
    selectedItem(newValue) {
      this.searchText = newValue.name;

    },
    showSelect(newValue) {
      if (!newValue && this.searchText === '') {
        this.searchText = this.selectedItem.name;
      }
    },

    items(newValue) {
      this.searchText = ''
    },
    searchText(search) {
      this.$emit("onSearch", search)
    }
  },

  methods: {
    onClick(item) {
      this.$emit("onItemClick", item)
      this.onShowSelect()
    },

    onShowSelect() {
      this.searchText = ''
      this.showSelect = !this.showSelect
    },

    filteredLanguages() {
      return this.items.filter((lg) => {
        return lg.name.toLowerCase().match(this.searchText.toLowerCase());
      });
    },

  },
};
</script>
