<template>
  <div class="h-full ">
    <div class="h-[70px] px-2 justify-between flex items-center">
      <router-link :to="{name: 'DashboardHomePage'}"
                   class=" p-2 borderw
            border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
        <Icon class="cursor-pointer" icon-name="HomeIcon" :icon-size="6" icon-type="outline"/>
      </router-link>
        <CustomSelect :items="songbooks"
                      label=""
                      class="w-full mx-1"
                      :selectedItem="selectedSongBook!==null?selectedSongBook:currentSongbook"
                      @onItemClick="onClickSongBook"/>
      <Icon class="cursor-pointer m-3" icon-name="PlusCircleIcon" :icon-size="6" icon-type="outline"/>
    </div>
    <div class=" h-full  flex overflow-y-auto "
         style="max-height: calc(var(--doc-height) - 140px)">
      <song-list v-if="songs.length>0" :songs="songs" :addIcon="true"/>
    </div>
  </div>
</template>

<script>
import SongList from "../../SongList";
import {mapState} from "vuex";
import {GET_SONGS_BY_SONGBOOK} from "../../../store/types";
import CustomSelect from "../../common/CustomSelect";

export default {
  name: "AdminEditAsideBlock",
  components: {CustomSelect, SongList},
  data() {
    return {
      selectedSongBook: null,
    }
  },
  beforeCreate() {
    this.$store.dispatch(GET_SONGS_BY_SONGBOOK, this.$route.query.songbook || 1)
  },
  computed: {
    ...mapState(["songs", "currentSongbook"]),
    currentRouteName() {
      return this.$route.name
    },
    songbooks() {
      return this.$store.state.songbooks
    }
  },
  methods:{
    onClickSongBook(songbook){

    }
  }
}
</script>

<style scoped>

</style>