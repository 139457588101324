/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {

    },
    registered () {
    },
    cached () {
    },
    updatefound () {
      // new content clear cache so user gets the new version
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
    },
    updated () {
    },
    offline () {
    },
    error (error) {
    }
  })
}
