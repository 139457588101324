import store from '@/store';
import router from '@/router'

import {useRoute} from 'vue-router'
import * as type from "../store/types";

export default {
    store,
    router,
    useRoute,
    install: (app, options) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$checkAuth = () => {
            return new Promise(function (resolve, reject) {
                const pageGuest = ['Login', 'Register', 'Home','StreamPage','RadioPage'];
                if (pageGuest.some((element) => element === router.currentRoute.value.name)) {
                    return true;
                }
                const token = window.localStorage.getItem('TOKEN') || store.state.sessions.access_token;
                if (token !== null) {
                    store.dispatch(type.GET_USER_ME).then((body) => {
                        resolve();
                    }).catch(
                        (err) => {
                            store.commit(type.LOGOUT)
                        }
                    );
                } else {
                    router.push({name: 'Login'})
                }
            })
        }
    }
}
