<template>
  <div class="border-t-2 px-8 flex justify-between items-center  w-full" style="height: var(--user-info-block-height)">
    <!--    <div :class="['flex justify-between items-center px-8 w-full',session??'invisible']">-->
    <div v-show="showProfileMenu" @mouseleave.prevent="showProfileMenu = false"
         :class="['absolute bottom-16 border text-gray-700 rounded-lg w-60 shadow-xl z-30  bg-white']">
      <div class="">
        <router-link :to="{name: 'Profile'}"
                     class="flex w-full text-gray-500 h-14 hover:text-white items-center text-gray-500 space-x-2 px-4 py-2 hover:bg-gray-300 ">
          <Icon icon-type="outline" icon-name="UserCircleIcon" :icon-size="5"/>
          <span>Profile</span>
        </router-link>
        <button @click="()=> $store.commit('logout')"
                class="flex w-full h-14 text-gray-500 hover:text-white items-center text-gray-500 space-x-2 px-4 py-2 hover:bg-gray-300 ">
          <Icon icon-type="outline" icon-name="LogoutIcon" :icon-size="5"/>
          <span>Logout</span>
        </button>
      </div>
    </div>
    <!--    public   -->
<!--    <button  v-if="session" type="button" @click.prevent="showProfileMenu = !showProfileMenu"-->
<!--            :class="[showProfileMenu ? 'bg-gray-500 ':'','user-btn']">-->
<!--      <span class="w-6 h-6">{{ ('godson').charAt(0).toUpperCase() }}</span>-->
<!--    </button>-->
<!--    <button v-else type="button" class="user-btn" @click="()=> $router.push({name: 'Login'})"-->
<!--            :class="[showProfileMenu ? 'bg-gray-500 ':'',]">-->
<!--      <Icon icon-name="UserCircleIcon" icon-type="solid" :icon-size="6"/>-->
<!--    </button>-->
    <div @mouseenter.prevent="showProfileMenu = false">
      <router-link :to="{name: 'RadioPage'}"
                   class="tooltip  user-btn">
        <img src="@/assets/radio.svg" class="h-5 w-5 text-red-500">
        <span class="tooltiptext bg-gray-500 z-40">Radio</span>
      </router-link>
    </div>
    <!--    auth   -->
    <div class="hidden" v-if="session" @mouseenter.prevent="showProfileMenu = false">
      <router-link type="button"  :to="{name: 'FavoritePage'}"
              class="tooltip  user-btn" >
        <Icon icon-name="HeartIcon" icon-type="solid" :icon-size="5"/>
        <span class="tooltiptext bg-gray-500 z-40">Favourites</span>
      </router-link>
    </div>
    <div class="hidden" v-if="session" @mouseenter.prevent="showProfileMenu = false" >
      <button type="button"
              class="tooltip  user-btn" >
        <Icon icon-name="CollectionIcon" icon-type="solid" :icon-size="5"/>
        <span class="tooltiptext bg-gray-500 z-40">Collections</span>
      </button>
    </div>
    <div @mouseenter.prevent="showProfileMenu = false">
      <a href="https://wa.me/79998108252" target="_blank"
              class="tooltip user-btn " >
        <Icon icon-name="MailIcon" icon-type="solid" :icon-size="5"/>
        <span class="tooltiptext  bg-gray-500 z-40">Contact us</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserBar",

  data() {
    return {
      showProfileMenu: false
    }
  },
  computed: {
    session() {
      return this.$store.state.sessions.access_token;
    }
  },
}
</script>

<style scoped>

</style>