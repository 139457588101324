<template>
  <div class="sm:hidden text-center lg:flex justify-center w-full h-full bg-gray-200 ">
    <div
        class="w-9/12 left-1/2 px-16 my-10 py-4 flex flex-col items-center bg-white 2xl:py-12 rounded-lg shadow-md">
      <div class="font-bold text-3xl mb-12 text-gray-700">Atmosphere App</div>
      <img src="@/assets/thumbnail.png" alt="Logo" class="w-60"/>
      <p class="mt-8 text-md 2xl:text-md px-10">
        Now, you’ve got to create an atmosphere, and your faith will do it. They
        were all in the upper room with one accord, when suddenly there came
        from heaven as a sound of a rushing mighty wind; the atmosphere was
        right. It takes a atmosphere to do anything.
        <span class="block mt-3 text-gray-400">Rev. William Marrion Branham <br/>
          <i>(57-0305 - Divine Love)</i>
        </span>
        <a href="https://play.google.com/store/apps/details?id=ru.godsonpeya.atmosphere"
           target="_blank"
           class="flex flex-col items-center justify-center">
          <img src="@/assets/android.webp" alt="" class="h-[70px]">
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomePanel"
}
</script>

<style scoped>

</style>