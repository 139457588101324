import language from "./actions/language";
import songbook from "./actions/songbook";
import songs from "./actions/songs";
import toast from "./actions/toast";
import auth from "./actions/auth";
import radio from "./actions/radio";
import favorite from "./actions/favorite";
import stream from "./actions/stream";

const actions = Object.assign({}, language, songbook, songs, toast, auth, radio, favorite, stream);

export default actions;
