<template>
  <div class="flex justify-between bg-gray-100 items-center h-screen lg:p-0 p-6">
    <div class="max-w-sm w-full mx-auto">

      <div class="flex flex-col w-full  ">
        <div class="flex flex-row items-center space-x-1">
          <h2 class="text-center  text-2xl font-extrabold text-gray-700 / lg:text-4xl">
            Login to Atmosphere
          </h2>
          <span class="h-2 w-2 rounded-full bg-purple-500 mt-3 lg:mt-5"></span>
        </div>

        <div class="">
          <p class="mt-2 text-left text-sm text-gray-600">
            Don't have an account ?
            <router-link :to="{ name: 'Register'}" class="font-medium text-purple-600 hover:text-purple-500">
              Create One
            </router-link>
          </p>
        </div>
      </div>

      <form class="mt-8 space-y-6 bg-gray-200 p-4 rounded-md" autocomplete="off">
        <div class="flex flex-col space-y-4 text-gray-500">
          <div class="text-left space-y-2">
            <label>Username</label>
            <div class="relative w-full">
              <input
                  autocomplete="email"
                  type="text"
                  v-model="user.email"
                  class="rounded-md w-full pl-10 border-gray-300 bg-white py-2.5 text-sm outline-none shadow-sm"
                  placeholder="Username"
              />
              <Icon icon-name="UserIcon" class="absolute inset-y-2 ml-3 flex-shrink-0 text-gray-400" icon-type="outline"
                    :icon-size="6"/>
            </div>
          </div>

          <div class="text-left space-y-2">
            <label>Password</label>
            <div class="relative w-full">
              <input
                  autocomplete="current-password"
                  type="password"
                  v-model="user.password"
                  class="rounded-md w-full pl-10 border-gray-300 bg-white py-2.5 text-sm outline-none shadow-sm"
                  placeholder="Your password"
              />
              <Icon icon-name="KeyIcon" class="absolute inset-y-2 ml-3 flex-shrink-0 text-gray-400" icon-type="outline"
                    :icon-size="6"/>
            </div>
          </div>

        </div>

        <div>
          <button @click.prevent="onLogin" type="submit" :disabled="isSendingRequest"
                  class="button sm:text-base px-3 py-3 bg-purple-500 text-white w-full hover:bg-purple-700 rounded-md">
            {{ isSendingRequest ? 'Sending...' : 'Login' }}
          </button>
        </div>

        <div class="text-sm text-center ">
          <router-link to="" class="font-medium text-purple-600 hover:text-purple-500">
            Forgot your password?
          </router-link>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import {SEND_AUTH} from "@/store/types";

export default {
  name: "Login",

  created() {
    this.$checkAuth().then(body => {
    })
  },

  mounted() {
    if (this.token && this.token.length > 0) {
      this.$router.push({name: 'Home'})
    }
  },

  data() {
    return {
      isSendingRequest: false,
      user: {
        email: null,
        password: null
      }
    }
  },

  computed: {
    token() {
      return window.localStorage.getItem('TOKEN')
    }
  },

  methods: {
    onLogin() {
      if (this.user.email !== null && this.user.password !== null) {
        this.$store.dispatch(SEND_AUTH, this.user).then(body => {
          if (body.code === 200) {
            this.$router.push({name: 'Home'})
          }
        })
      }
    }
  }
};
</script>

