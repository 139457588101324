import * as type from "../types";

export default {
    [type.ADMIN_GET_SONGBOOKS]: ({commit, getters}) => {
        return getters.GET(`/admin/songbooks`).then((data) => {
            if (data)
                commit(type.GET_SONGBOOKS, data);
            return data
        });
    },
    [type.GET_SONGBOOKS]: ({commit, getters}) => {
        return getters.GET(`/songbooks`).then((data) => {
            if (data)
                commit(type.GET_SONGBOOKS, data);
            return data
        });
    },
    [type.GET_SONGBOOKS_BY_LANGUAGE_ID]: ({commit, getters}, langId) => {
        return getters.GET(`/songbooks/lang/${langId}?hidden=true`).then((data) => {
            if (data)
                commit(type.GET_SONGBOOKS, data);
            return data
        });
    },
    [type.GET_CURRENT_SONGBOOK]: ({commit, getters}, sonBookId) =>
        getters.GET(`/songbooks/${sonBookId}`).then((data) => {
            if (data)
                commit(type.GET_CURRENT_SONGBOOK, data);
            return data
        }),

    [type.GET_SONGBOOKS_BY_LANGUAGE]: ({commit, getters}, langId) => {
        return getters.GET(`/songbooks/lang/${langId}?hidden=true`).then((data) => {
            if (data)
                commit(type.GET_SONGBOOKS, data);
            return data
        });
    },

    [type.UPLOAD_SONGBOOKS]: ({commit, getters}, files) => {
        return getters.ADMIN_POST_LOADER(`/songbooks/upload`, files, commit).then((data) => {
            if (data)
                commit(type.UPLOAD_SONGBOOKS, data);
            return data
        });
    },
};
