<template>
  <Menu as="div" v-if="translatedLanguages && translatedLanguages.length>0">
    <div>
      <MenuButton
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm p-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
        <Icon class="cursor-pointer" icon-name="GlobeAltIcon" :icon-size="5" icon-type="outline"/>
      </MenuButton>
    </div>


    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
          class="origin-top-right absolute right-10 mt-2 w-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="origin-top-right absolute right-4 lg:right-4  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 w-40">
          <MenuItem v-slot="{ active }"
                    v-for="language in translatedLanguages"
                    :key="language.code">
            <div class="flex flex-col ">
              <button
                  @click="translateSong(language)"
                  class="hover:bg-gray-100 rounded-tr-md rounded-tl-md text-gray-500 py-2 pl-4 md:text-sm text-xs flex flex-row space-x-3 border-b items-center"
              >
                {{ language.name }}
              </button>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {ClipboardIcon, DotsVerticalIcon, ShareIcon,} from "@heroicons/vue/outline";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {useToast} from "vue-toastification";
import {GET_SONG_ANALOG, GET_TRANSLATED_LANGUAGES} from "../../../../store/types";

export default {
  name: 'TanslateMenu',
  components: {
    DotsVerticalIcon,
    ShareIcon,
    ClipboardIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
  },
  props: {
    song: {
      type: Object,
      default: null,
      required: true
    },
  },
  setup() {
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
    }
  },

  computed: {
    translatedLanguages() {
      return this.$store.state.translatedLanguages
    },
  },
  methods: {
    translateSong(language) {
      this.$store
          .dispatch(GET_SONG_ANALOG, {
            songCode: this.song.code,
            langCode: language.code,
          })
          .then((res) => {
            if (res.code) {
              this.$store.dispatch(GET_TRANSLATED_LANGUAGES, res.code);
            }
          });
    },
  }
}
</script>
