<template>
    <div class="flex items-center justify-center h-full text-4xl font-bold">
      User Profile
    </div>
</template>

<script>
import {SET_LAYOUT} from "../../../store/types";

export default {
  name: "Profile",
  beforeCreate() {
    this.$store.commit(SET_LAYOUT, 'single-layout')
  },
}
</script>

<style scoped>

</style>