<template>
  <div v-if="fromBackend">
    <div class="pb-8">
      <p class="mb-4">Vous n'avez pas trouve le cours que vous cherchez? Suggerez-le!</p>
      <i class="text-red-700" v-if="error">Les donnees fournies ne sont pas valides!</i>
      <div class="flex flex-col space-y-2">
        <input class="border border-gray-300 p-2" type="text" v-model="course.name" placeholder="Nom du cours">
        <input class="border border-gray-300 p-2" type="text" v-model="course.content" placeholder="le logo">
        <button type="button" class="p-2 bg-green-300" @click="create">{{ course.id ? 'Edit' : 'Create' }}</button>
      </div>
    </div>
    <ul>
      <li class="w-full p-4 border shadow hover:bg-gray-200 flex justify-between"
          v-for="data in fromBackend" :key="data.id"
          @click="editCourse(data)">
        <div class="flex-col">
          <div>
            <span class="pr-4">{{ data.id }}</span>
            <span>{{ data.name }}</span>

          </div>
          <div>
            {{ data.content }}
          </div>
        </div>
        <div class="flex space-x-4">
          <span @click="deleteCourse(data)" class="text-red-700 cursor-pointer">X</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: "TestPage",

  data() {
    return {
      fromBackend: [],
      course: {
        id: null,
        name: "",
        content: ""
      },
      error: false
    }
  },

  beforeCreate() {
    axios.get("http://localhost:8081/api/courses/").then(body => {
      this.fromBackend = body.data
    })
  },
  methods: {
    create() {
      if (this.course && this.course.name.length > 0 && this.course.content.length > 0) {
        if (this.course.id) {
          axios.put(`http://localhost:8081/api/courses/${this.course.id}`, this.course).then(body => {
            this.updateData()
          })
        } else {
          axios.post("http://localhost:8081/api/courses", this.course).then(body => {
            this.fromBackend.push(body.data)
          })
        }
        this.course.name = ""
        this.course.content = ""
      } else {
        this.error = true
      }
    },
    deleteCourse(data) {
      if (confirm("Are you sure to delete it?")) {
        axios.delete(`http://localhost:8081/api/courses/${data.id}`).then(body => {
          this.updateData()
        })
      }
    },
    updateData() {
      axios.get("http://localhost:8081/api/courses/").then(body => {
        this.fromBackend = body.data
      })
    },
    editCourse(data) {
      this.course = data
      // if (confirm("Are you sure to delete it?")){
      //   axios.delete(`http://localhost:8088/${data.id}`).then(body=>{
      //     axios.get("http://localhost:8088").then(body=>{
      //       this.fromBackend=body.data
      //     })
      //   })
      // }
    }
  },
}
</script>

<style scoped>

</style>